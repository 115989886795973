import concert from './assets/img/category/concerts.png';
import sport from './assets/img/category/Sport.png';
import football from './assets/img/category/football.png';
import hockey from './assets/img/category/hockey.png';
import fest from './assets/img/category/fest.png';
import theatre from './assets/img/category/theatre.png';
import kids from './assets/img/category/kids.png';

const BASE_URL = 'https://preview-api.eticket4.ru';
// http://212.41.9.181:8899/swagger-ui/index.html?configUrl=/open-api-docs/swagger-config#/

// const BASE_URL = 'http://185.30.18.70:18080';
// http://185.30.18.70:18080/swagger-ui/index.html?configUrl=/open-api-docs/swagger-config#/

export const LINKS = {
	MAIN_ALL_EVENTS: `${BASE_URL}/`,
	MAIN_ALL_EVENTS_FILTER: `${BASE_URL}/filter`,
	PROFILE_DATA: `${BASE_URL}/profile/data`,
	PROFILE_DEALS: `${BASE_URL}/profile/deals`,
	PROFILE_TRADES: `${BASE_URL}/profile/trades`,
	PROFILE_PAYMENTS: `${BASE_URL}/profile/payments`,
	PROFILE_PAYMENT_DATA: `${BASE_URL}/profile/payment_data`,
	SELL_INFO_PAGE: `${BASE_URL}/sell`,
    EVENT_INFO: `${BASE_URL}/events/@id@`,
	USER_ME: `${BASE_URL}/users/me`,
    USER_TOKEN_REFRESH: `${BASE_URL}/users/jsw/refresh`,
    USER_SING_IN_OR_UP: `${BASE_URL}/users/sign-in-or-up`,
    USER_SEND_SMS_CODE: `${BASE_URL}/users/send-sms-code`,
    USER_CONFIRM_SMS: `${BASE_URL}/users/confirm-sms-code`,
    USER_IS_AUTHENTICATED: `${BASE_URL}/users/is-authenticated`,
    USER_SING_IN_BY_LOGIN: `${BASE_URL}/users/sign-in/by-login`,
    USER_SEND_CONFIRM_LINK: `${BASE_URL}/users/send-email-link`,
    FILE_GET_IMAGE_URL: `${BASE_URL}/file/image/event/@id@/@size@`,
    SUB_EVENT_FAVORITE_TOGGLE: `${BASE_URL}/subevent/favourite`,
};

const localStorage = {
	сity:'Текущий город',
	user: 'Данные пользователя'
};

export const COLORS = {
	white: '#FFF',
    red: '#D31F26',
    black: '#151820',
    darkGray: '#404040',
    semiGray: '#BDBDBD',
    lightGray: '#F6F7F8',
    whiteBlue: '#F4F8FD',
    whitePink: '#FFF4F4',
    green: '#4C8F23',
}

export const COLORS_CATEGORIES = {
    concert: '#D31F26',
    sports: '#29419C',
    football: '#4C8F23',
    hockey: '#707378',
    theatre: '#151820',
    festival: '#78339B',
    kids: '#DBAC06',
}

export const FONTS = {
    regular: 'Gilroy-regular',
    medium: 'Gilroy-medium',
    bold: 'Gilroy-bold',
};

export const BREAK_POINTS = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	// tablet: '768px',
	tablet: '720px',
	laptop: '1140px',
	// laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px'
}

export const MEDIA_QUERY = {
	mobileS: `(min-width: 320px)`,
	mobileM: `(min-width: 375px)`,
	mobileL: `(min-width: 425px)`,
	tablet: `(min-width: 720px)`,
	laptop: `(min-width: 1140px)`,
	laptopL: `(min-width: 1440px)`,
	desktop: `(min-width: 2560px)`,
	desktopL: `(min-width: 2560px)`
};


// TODO check refactor like object { category_name1:{}, category_name2:{}, category_name3:{},}
// get from getEventMainPage data.categoryList
export const CATEGORIES = [
	{
		id: '0',
		key: 'c-0',
		category: 'Концерты',
		dataBaseName: 'kontserti',
		href: 'concerts',
		color: COLORS_CATEGORIES.concert,
		img: {
			path: concert,
			alt: 'декоротивный билет',
		},
	},
	{
		id: '1',
		key: 'c-1',
		category: 'Спорт',
		dataBaseName: 'sport',
		href: 'sports',
		color: COLORS_CATEGORIES.sports,
		img: {
			path: sport,
			alt: 'декоротивный билет',
		},
	},
	{
		id: '2',
		key: 'c-2',
		category: 'Футбол',
		dataBaseName: 'football',
		href: 'football',
		color: COLORS_CATEGORIES.football,
		img: {
			path: football,
			alt: 'декоротивный билет',
		},
	},
	{
		id: '3',
		key: 'c-3',
		category: 'Хоккей',
		dataBaseName: 'hockey',
		href: 'hockey',
		color: COLORS_CATEGORIES.hockey,
		img: {
			path: hockey,
			alt: 'декоротивный билет',
		},

	},
	{
		id: '4',
		key: 'c-4',
		category: 'Театры',
		dataBaseName: 'teatr',
		href: 'theatre',
		color: COLORS_CATEGORIES.theatre,
		img: {
			path: theatre,
			alt: 'декоротивный билет',
		},
	},
	{
		id: '5',
		key: 'c-5',
		category: 'Фестивали',
		dataBaseName: 'festivali',
		href: 'festival',
		color: COLORS_CATEGORIES.festival,
		img: {
			path: fest,
			alt: 'декоротивный билет',
		},
	},
	{
		id: '6',
		key: 'c-6',
		category: 'Детям',
		dataBaseName: 'detjam',
		href: 'kids',
		color: COLORS_CATEGORIES.kids,
		img: {
			path: kids,
			alt: 'декоротивный билет',
		},
	},
];

// export const IMAGES = {
//     DEFAULT_AVATAR: require('./assets/images/defaultUserAvatar.png'),
// };

export const TICKET_OPTIONS = [
    { text: "Возможен торг", type: "bargain", isChecked:false },
    { text: "Оставлять один билет", type: "oneTicket", isChecked:false },
    { text: "Авто-подтверждение", type: "autoConfirmation", isChecked:false }
]

export const TICKET_TYPE = [
	{ title: "Бумажный билет", type: 'paperTicket' },
	{ title: "Электронный билет", type: 'eTicket' },
	{ title: "Абонемент", type: 'sessionTicket' },
]

export const QUESTIONS = [
    {
        id:1,
        title: 'Когда я получу деньги за билеты?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:2,
        title: 'Как редактировать объявление?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:3,
        title: 'Как продать бумажный билет?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:4,
        title: 'Ошибка в объявлении. Что делать?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:5,
        title: 'Когда я получу деньги за билеты?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:6,
        title: 'Как редактировать объявление?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:7,
        title: 'Как продать бумажный билет?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
    {
        id:8,
        title: 'Ошибка в объявлении. Что делать?',
        text:'Lorem,  ipsam corrupti molestias minima. Id dolor quasi commodi praesentium et, vitae ullam magnam quidem delectus sunt labore at, recusandae!'
    },
];