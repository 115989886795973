import React from 'react';
import { Container } from './styles';
import OrdersOffersCard from '../../../components/OrdersOffersCard/OrdersOffersCard';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import styled from 'styled-components';

const mockItems = [
    { title: '#12345', event: 'Twenty One Pilots', status: 'Ожидает оплаты', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022', isBuy:true },
    { title: '#54321', event: 'Twenty One Pilots', status: 'Оплачен', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022', isBuy:false }
];

export default function MyOrders() {
    //TODO get data from backend

    return (
        <Container>
            <TitleH2>Мои заказы</TitleH2>
            {/* {mockItems.map((item, index) =>{
                <OrdersOffersCard key={index} item={item} isOrder />
            })} */}
            <OrdersOffersCard item={mockItems[0]} isOrder />
            <OrdersOffersCard item={mockItems[1]} isOrder />
        </Container>
    );
}