import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../config';
import ActivityIndicator from '../ActivityIndicator';

const Btn = styled.button`
	box-sizing: border-box;
	display: inline-flex;
	justify-content: center;
	border-radius:${props => props.isNotRound ? `12px` : `32px`};
	padding: 18px 0;
	background-color: ${COLORS.red};
	width: 60%;
	color: ${COLORS.white};
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	font-size: 14px;
	border: none;
	text-transform: uppercase;
	&:focus {
		background-color: ${COLORS.red};
	}
	&:focus-visible {
		outline: none;
	}
	&#activeBlur {
		opacity: .5;
	}
	@media screen and ${MEDIA_QUERY.laptop} {
		width: 246px;
	}
`;

//TODO replace on ButtonForm and remove component

export default function FillBtnForm(props) {
	return (
		<Btn
			type={props.type}
			disabled={props.disabled}
			className={props.className? props.className : "more-btn"}
			id={props.isValid ? 'activeBlur' : 'null'}
			isNotRound={props.isNotRound}
			onClick={props.callback}
		>
			{props.isLoading ? <ActivityIndicator /> : props.title}
		</Btn>
	);
}
