/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import HooksApi from '../../../API/hooksApi';
import { CATEGORIES } from '../../../config';
import ChipList from '../../Chip/ChipList';
import dayjs from 'dayjs';

export default function MainCardSlider({ data }) {
	const { start, yearsTxt, category, city, name, event_id, minShowroomPrice } = data;
	const { color } = CATEGORIES.find((element) => element.category === category);
	const date = dayjs(start).format('D MMMM YYYY');
	const [srcImg, setSrcImg] = useState('');

	useEffect(() => {
		HooksApi.getImageForEvent(event_id).then((data) => { setSrcImg(data); });
	}, []);

	return (
		<div className="eventCard">
		<img className="img" src={srcImg} alt="" />
		<div className="dark-bg">
			<div className="eventCard__info eventInfo">
			<h3 className="eventInfo__name"> {name} {' '}
				<span className="eventInfo__yo">{yearsTxt}</span>
			</h3>
			<div className="eventInfo__place">
				<span>{date}</span>
				{' '}
				•
				<span>{city}</span>
			</div>
			<ChipList cost={minShowroomPrice} categoryName={category} color={color} />
			</div>
		</div>
		</div>
	);
}
