import axios from 'axios';
import { LINKS } from '../config';
import Utils from '../services/utils/Utils';

// тестовый юзер
// login: goldman-d@mail.ru
// password: q6q5q4q3Q2
//phone Alexei 89204912323

class AuthService {
    static async signInOrUp(userPhone) {
        return await axios.post(LINKS.USER_SING_IN_OR_UP, null, { params: { phone: userPhone } });
    }
	
    static async signInByLogin(userLogin, userPassword) {
		const user = { login: userLogin, password: userPassword };
        return await axios.post(LINKS.USER_SING_IN_BY_LOGIN, user, null);
    }

    static async confirmSms(userPhone, userCode) {
        return await axios.post(LINKS.USER_CONFIRM_SMS, { phone: userPhone, code: parseInt(userCode) }, null);
    }

	//not use ?
    static async sendSmsCode(userPhone) {
        return await axios.post(LINKS.USER_SEND_SMS_CODE, null, { params: { phone: userPhone} });
    }

    static async sendConfirmLink(userLogin) {
        return await axios.post(LINKS.USER_SEND_CONFIRM_LINK, null, { params: { login: userLogin } });
    }

	static async userMeInfo() {
        const userData = Utils.getUserData();
        const config = { headers: { 'Authorization': 'Bearer ' + userData.jwt } };
        return await axios.get(LINKS.USER_ME, config);
    }

    static async userMeSet(obj) {
        const userData = Utils.getUserData();
        const config = { headers: { 'Authorization': 'Bearer ' + userData.jwt } };
        return await axios.put(LINKS.USER_ME, config, { params: obj });
    }
}

export default AuthService