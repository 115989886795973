import React from "react";

const ApprovalHand = () => {
    return (
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="30.5076" cy="30.5" rx="29.8826" ry="30.5" fill="#4C8F23"/>
            <path d="M44.0224 26.504C43.6289 26.0655 43.1366 25.7124 42.5802 25.4698C42.0238 25.2273 41.4168 25.101 40.8021 25.1H34.4175L35.2016 23.241C35.5277 22.4271 35.6363 21.5518 35.5181 20.6903C35.3999 19.8289 35.0583 19.0069 34.5228 18.2949C33.9872 17.5828 33.2737 17.0021 32.4433 16.6024C31.6129 16.2027 30.6904 15.996 29.7551 16.0001C29.4858 16.0006 29.2223 16.0732 28.9963 16.2093C28.7704 16.3453 28.5914 16.539 28.481 16.7671L24.4907 25.1H21.2004C20.0864 25.1 19.018 25.5109 18.2303 26.2423C17.4425 26.9737 17 27.9657 17 29V38.1C17 39.1343 17.4425 40.1263 18.2303 40.8577C19.018 41.5891 20.0864 42 21.2004 42H39.0239C40.0065 41.9997 40.9579 41.6795 41.7126 41.0952C42.4673 40.5109 42.9774 39.6995 43.1543 38.802L44.9324 29.702C45.0424 29.1396 45.0179 28.5616 44.8606 28.009C44.7034 27.4564 44.4172 26.9426 44.0224 26.504ZM24.0006 39.4H21.2004C20.829 39.4 20.4729 39.263 20.2103 39.0192C19.9478 38.7754 19.8002 38.4448 19.8002 38.1V29C19.8002 28.6552 19.9478 28.3246 20.2103 28.0808C20.4729 27.837 20.829 27.7 21.2004 27.7H24.0006V39.4ZM42.2022 29.234L40.424 38.334C40.3644 38.6369 40.1909 38.9102 39.9345 39.1054C39.6781 39.3006 39.3554 39.405 39.0239 39.4H26.8009V26.673L30.6092 18.7171C31.0012 18.8232 31.3653 19.0036 31.6784 19.2469C31.9915 19.4902 32.2469 19.7912 32.4285 20.1308C32.6101 20.4704 32.7138 20.8412 32.7332 21.2199C32.7527 21.5986 32.6873 21.977 32.5414 22.331L31.7993 24.19C31.6412 24.583 31.5878 25.0056 31.6437 25.4218C31.6996 25.8381 31.8632 26.2356 32.1204 26.5804C32.3776 26.9252 32.7209 27.2072 33.1209 27.4021C33.5209 27.5971 33.9657 27.6993 34.4175 27.7H40.8021C41.0078 27.6997 41.211 27.7415 41.3973 27.8224C41.5837 27.9033 41.7485 28.0213 41.8802 28.168C42.015 28.3128 42.1138 28.4833 42.1695 28.6676C42.2252 28.8519 42.2363 29.0453 42.2022 29.234Z" fill="white"/>
        </svg>
    );
}

export default ApprovalHand;