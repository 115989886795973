import React from 'react';
import { Container } from './styles';
import { TitleH2, Text, TextBold } from '../../../modules/css/CommonStyles';
import NoFillBtn from  '../../../components/Buttons/NoFillBtn';
import { COLORS, FONTS, MEDIA_QUERY } from '../../../config';
import styled from 'styled-components';


export default function PaymentВetails() {

    const BankCardItem = ({cardTitle, cardNumber, onPress})=>{
        const BankCard = styled.div`
            flex-direction: column;
            background-color:#fff;
            border:1px solid #000;
            border-radius: 20px;
            padding 24px 32px;
            height:200px;
            max-width:485px;
            display: flex;
            align-items:center;
            flex-direction: column;
            justify-content: space-between;
            @media screen and ${MEDIA_QUERY.tablet} {
                height:260px;
                align-items:start;
                justify-content: normal;
            }
        `;

        const BankCardContent = styled.div`
            width: 100%;
            flex:0.6;
            display: flex;
            flex-direction: column;
            text-align: center;
            @media screen and ${MEDIA_QUERY.tablet} {
                width: auto;
                text-align: auto;
            }
        `;

        const BankCardTitle = styled.span`
            width: 100%;
            font-size: 17px;
            font-weight: 700;
            font-family: ${FONTS.bold};
            text-transform: uppercase;
            @media screen and ${MEDIA_QUERY.tablet} {
                font-size: 24px;
            }
        `;
        const BankCardNumber = styled.span`
            width: 100%;
            font-size: 24px;
            font-weight: 700;
            color: ${COLORS.semiGray};
            font-family: ${FONTS.bold};
            text-transform: uppercase;
        `;

        const onPressButtom = ()=>{
            if(cardNumber){
                console.log('changeCart');
            } else {
                console.log('addNewCart');
            }
            
        };

        return (
            <BankCard>
                <BankCardContent>
                    <BankCardTitle>{cardNumber ? cardTitle : 'Банковская карта не добавлена'}</BankCardTitle>
                    <BankCardNumber>{cardNumber ? cardNumber : ''}</BankCardNumber>
                </BankCardContent>
                <NoFillBtn textValue={cardNumber ? 'Сменить карту' : 'Добавить карту'} callback={onPressButtom} id="ByAddCard" />
            </BankCard>
        )
    };

    return (
        <Container> 
            <TitleH2>Платежные данные</TitleH2>
            <Text>
                Чтобы получить оплату за реализованные билеты, необходимо указать данные банковской карты,на нее мы выполним перевод.
                <TextBold>Выплата производится с помощью сервиса PayU в течении 2-х рабочих дней. </TextBold>
                <TextBold color={COLORS.red}>Комиссия за перевод - 1,5%.</TextBold>
            </Text>
            {/* <BankCardItem cardTitle={'Банковская карта'} cardNumber={1243215214} /> */}
            <BankCardItem />
        </Container>
    );
}