import React from 'react';
import styled from 'styled-components';
import { useSwiper } from 'swiper/react';
import { COLORS } from '../../config';

const BtnSlide = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${COLORS.red};
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
      background-color: ${COLORS.red} !important;
    }
`;

export default function BtnSlider(props) {
  const swiper = useSwiper();

  return (
    <BtnSlide onClick={() => (props.isNext ? swiper.slideNext() : swiper.slidePrev())} className={props.className}>
      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 16L9 8.5L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </BtnSlide>
  );
}
