import React from 'react';
import { COLORS, MEDIA_QUERY } from "../../config";


const ActivityIndicator = () => {
    return (
        <div class="preloader-wrapper small active">
            <div class="spinner-layer spinner-white-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    );
};

export default ActivityIndicator;