import React from 'react';

export default function BtnClose(props) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={props.callback} className="close-btn">
      <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5745 1L1 17M1 1L21 17" stroke="#151820" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </a>
  );
}
