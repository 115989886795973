import React from 'react';
import { useStore } from "effector-react";
import { $modalState } from "../../../modules/effector/modal";
import ChooseDate from './ChooseDate';

export default function DateRangeModal() {
    const modalState = useStore($modalState);

	return (
		<>
            {(() => {
                switch (modalState.modalType) {
                    case 'сhooseDate':
                        return <ChooseDate />;
                }
            })()}
        </>
	);
}
