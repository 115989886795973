import { createEffect, createEvent, createStore, sample } from 'effector';
import HooksApi from '../../API/hooksApi';
import Utils from '../../services/utils/Utils';

// @@@ TODO backend After description filter params from backend

const { getShuffleArray, setTownLogic, getUserData } = Utils;

export const getAllEvents = createEvent();
export const addToFavorite = createEvent();
export const setFilterEvents = createEvent();

export const $AllData = createStore({})
  .on(addToFavorite, (state, payload)=>{
    const isAuth = getUserData();
    if(isAuth){
      const {id, dbName} = payload;
      console.log({payload});
      let events = state.subEventList[dbName];
      events = events.map(event=>{
        if(event.event_id == id){
          event.favourite = !event.favourite;
        }
        return event
      });
      console.log({events});
      state.subEventList[dbName] = events;
    }
    console.log({state});
    return state
  });
// $AllData.watch(state => console.log('$AllData', state))

export const getAllEventsFx = createEffect(async () => {
    const response = await HooksApi.getEventMainPage();
    return response
})

//Wait backend
export const getAllEventsFilterFx = createEffect(async (data) => {
  console.log('getAllEventsFilterFx');
  if(data.option){
    const filterOptions = {
      filterType: data.option,
      filterCities: "string",
      startDate: "string",
      category: "string",
      endDate: "string",
      offset: "string",
      limit: 0
    };
    // const response = await HooksApi.getEventMainPageFilter(filterOptions);
    // return response //@@@
  }
})

sample({
    clock: getAllEvents,
    target: getAllEventsFx
})

sample({
    clock: getAllEventsFx.doneData,
    fn:(data)=> data,
    target: $AllData
})

sample({
    clock: setFilterEvents,
    fn:(data)=> data,
    target: getAllEventsFilterFx
})

// sample({ //@@@
//     clock: getAllEventsFilterFx.doneData,
//     fn:(data)=> data,
//     target: $AllData
// })

export const $citiesList = createStore([]);
export const $currentTown = createStore('');
sample({
    clock: $AllData,
    fn:(data)=> data.citiesList,
    target: $citiesList
})
sample({
    clock: $AllData,
    fn:(data)=> {
        if(data.citiesList?.length > 0) return data.citiesList[0]
    },
    target: $currentTown
})

// export const $currentTown = createStore([])
export const $categoryList = createStore([]);
sample({
    clock: $AllData,
    fn:(data)=> data.categoryList,
    target: $categoryList
})

export const $subEventList = createStore([]);
sample({
    clock: $AllData,
    fn:(data)=> data.subEventList,
    target: $subEventList
})

export const $shuffleEventList = createStore([]);
// $shuffleEventList.watch(state => console.log('$shuffleEventList', state))
sample({
    clock: $AllData,
    fn:(data)=> getShuffleArray(data.subEventList),
    target: $shuffleEventList
})

export const $isModalOpen = createStore(false);