import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { $currentEvent, $buyState, $arenaData, setBuyState, backStep } from '../../../modules/effector/currentEvent';
import { TitleH3 } from '../../../modules/css/CommonStyles';
import ArrowRight from '../../../assets/svg/arrowRight';
import FillBtnForm from '../../Buttons/FillBtnForm';
import { COLORS, FONTS } from '../../../config';

const Container = styled.div`
  background-color: #FFF;
  border-radius: 20px;
  height:100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  .tickets{
    flex:1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    background-color: ${COLORS.lightGray};

    &::-webkit-scrollbar {
      width: 10px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: ${COLORS.semiGray};
    }
    
    &::-webkit-scrollbar-thumb {
      border: 1px solid transparent;
      background-clip: content-box;
      background-color: ${COLORS.red};
    }


    .ticket{
      position: relative;
      padding:10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: ${COLORS.white};
      margin: 3px;
      padding: 10px;
      cursor: pointer;

      &.subTicket{
        margin-left: 25px;
      }

      .left, .right{
        display: flex;
        flex-direction: column;
      }

      .right{
        .choose{
          display:flex;
          .arrow {
            transition: 0.5s ease all;
            background: no-repeat 50%;
            background-size: 10px;
            width: 25px;
            height: 25px;
            &.rotateArrow{
              transform: rotate(180deg);
            }
          }
        }
      }

      .redText{
        color:${COLORS.red};
      }
      .boldText{
        font-family: ${FONTS.bold};
        font-size: 1.1rem;
      }
    }
  }

  .buyTitle{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .left, .right{
      display: flex;
      flex-direction: column;
    }

    .right{
      .choose{
        display:flex;
        .arrow {
          transition: 0.5s ease all;
          background: no-repeat 50%;
          background-size: 10px;
          width: 25px;
          height: 25px;
          &.rotateArrow{
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .fillContent {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to left, rgba(0,0,0, 0) 50%, rgba(0,0,0, 0.2) 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    &:hover{
      background-position: left;
    }
  }
`;

export default function ArenaContentList() {
  const currentEvent = useStore($currentEvent);
  const arenaData = useStore($arenaData);
  const buyState = useStore($buyState);

  const onSector = (id)=>{
    setBuyState({sector: id})
  };

  const onRow = (id)=>{
    setBuyState({row: id})
  };

  const onMouseOver = (id)=>{
    console.log(`onMouseOver ${id}`);
    const elements = document.getElementsByClassName(`${id}-zone`);
    console.log(elements);

  };
  const onMouseEnter = (id)=>{
    // console.log(` onMouseEnter${id}`);
    // const elements = document.getElementsByClassName(`${id}-zone`);
  };

  //TODO step buy

  return (
    <Container>
        {!buyState.row && <>
        <TitleH3>Выбранные билеты</TitleH3>
        <div className="tickets">
          {(currentEvent.arenaZoneBySubEventList.length > 0 && !buyState.sector) && currentEvent.arenaZoneBySubEventList.map((item, index)=>{
            if(item.name){
              return (
                <div className='ticket'
                  key={`${index}Zone`}
                  onClick={()=>{onSector(item.id)}}
                >
                  <div
                    className='fillContent'
                    onMouseOver={()=>{onMouseOver(item.id)}}
                    onMouseEnter={()=>{onMouseEnter(item.id)}}
                  ></div>
                  <div className="left">
                    <span className='redText'>{item.name}</span>
                    <span><span className='bold'>2</span> шт. от <span className='bold'>6000 ₽</span></span>
                  </div>
                  <div className="right">
                    <div className="choose">Выбрать  <ArrowRight className='arrow' /></div>
                  </div>
                </div> 
              )
            }
          })}

          {buyState.sector && <>
            <div className='ticket' onClick={()=>{backStep()}}>
              <div
                className='fillContent'
              ></div>
              <div className="left">
                <span className='redText'>{arenaData.sectionsName[buyState.sector].name}</span>
                <span><span className='bold'>2</span> шт. от <span className='bold'>6000 ₽</span></span>
              </div>
              <div className="right">
                <div className="choose">К другим секторам  <ArrowRight className='arrow rotateArrow' /></div> 
              </div>
            </div>
            <>
              {
                arenaData.sortByArenas[buyState.sector].map((item, index)=>{
                  return (
                    <div className='ticket subTicket' key={`${index}row`} onClick={()=>{onRow(item.id)}}>
                      <div
                        className='fillContent'
                      ></div>
                      <div className="left">
                        <span className='bold'>{item.name}</span>
                        <span>Места ?/?</span>
                      </div>
                      <div className="right">
                        <span className='redText'>6000 ₽/шт.</span> 
                        <span>Тип билета</span> 
                      </div>
                    </div>
                  )
                })
              }
            </>
          </>}
        </div>
        </>}
        {buyState.row && <div className="buyTitle" onClick={()=>{backStep()}}>
            <div className="left">
              <div>Зона:</div>
              <div>{arenaData.sectionsName[buyState.sector].name} / {arenaData.zoneList[buyState.row].name}</div>
            </div>
            <div className="right">
              <div className="choose">Назад <ArrowRight className='arrow rotateArrow' /></div> 
            </div>
          </div>}
        {/* <FillBtnForm onClick={onOrder} title="Оформить заказ" /> */}
    </Container>
  );
}
