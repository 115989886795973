import React, { useState } from 'react';
import { Container } from './styles';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS, FONTS } from '../../../config';
import ArrowUpDown from '../../../assets/svg/arrowUpDown';

const SortButtons = styled.div`
    display:flex;
`;
const SortButton = styled.a`
    display:flex;
    font-weight: 700;
    color:${COLORS.black};
    font-family: ${FONTS.regular};
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 20px;
    background-color: ${COLORS.lightGray};
`;

const Table = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    ${(props) => props.isHeader
        ? `border:1px solid ${COLORS.semiGray}; color:${COLORS.semiGray}; display:none;`
        : `border:1px solid ${COLORS.black}; font-weight:700; display:flex;`
    };
    border-radius:20px;
    padding:20px;
    margin-top: 24px;
    width:100%;
    align-items: center;
    justify-content: space-between;
    font-family: ${FONTS.bold};

    .fromHeader{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color:${COLORS.semiGray};
        font-family: ${FONTS.regular};
    }

    @media screen and ${MEDIA_QUERY.tablet} {
        justify-content: space-around;
        .fromHeader{
            display:none;
        }
        .innerColumns{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
		${(props) => props.isHeader
            ? `display:flex;`
            : ``
        };
	}
`;
const Column = styled.div``;

const dataMock = [
    {
        id:'ID9089908900',
        time:'11.11.2021 15:33',
        status:'Завершена',
        total: '30 000',
        accountId: '8900999998989',
    },
    {
        id:'ID9089908901',
        time:'12.01.2022 20:39',
        status:'Завершена',
        total: '20 000',
        accountId: '8900999998989',
    },
    {
        id:'ID9089908902',
        time:'14.05.2022 14:22',
        status:'Завершена',
        total: '10 000',
        accountId: '8900999998989',
    }
];

export default function MyPayments() {
    const [data, setData] = useState(dataMock);

    return (
        <Container>
            <TitleH2>Выплаты</TitleH2>
            <SortButtons>
                <SortButton>Выбрать дату</SortButton>
                <SortButton>Сортировать &nbsp;<ArrowUpDown color={COLORS.black} isUp={false} /></SortButton>
            </SortButtons>
            <Table>
            <TableRow isHeader>
                    <div>ID ТРАНЗАКЦИИ</div>
                    <div>ДАТА И ВРЕМЯ</div>
                    <div>СТАТУС</div>
                    <div>СУММА</div>
                    <div>СЧЕТ</div>
                </TableRow>
                {data.map((item, index)=>(
                    <TableRow key={index}>
                        <div className={'fromHeader'}>
                            <div>ID ТРАНЗАКЦИИ</div>
                            <div>ДАТА И ВРЕМЯ</div>
                            <div>СТАТУС</div>
                            <div>СУММА</div>
                            <div>СЧЕТ</div>
                        </div>
                        <div className={'innerColumns'}>
                            <div>{item.id}</div>
                            <div>{item.time}</div>
                            <div>{item.status}</div>
                            <div>{item.total}₽</div>
                            <div>{item.accountId}</div>
                        </div>
                    </TableRow>
                ))}
            </Table>
        </Container>
    );
}