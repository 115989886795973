
const getIndex = (searchParam, propsParam, arr) => arr.find((arrElement) => arrElement.propsParam === searchParam);

const getRandomInteger = (a = 0, b = 1) => {
    const lower = Math.ceil(Math.min(a, b));
    const upper = Math.floor(Math.max(a, b));

    return Math.floor(lower + Math.random() * (upper - lower + 1));
};

const getRandomIndex = (arr, startIndex = 0) => arr[getRandomInteger(startIndex, arr.length - 1)];

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const getShuffleArray = (obj) => {
    if (obj === null) { return; }
    let arrayEvent = [];
    for (const key in obj) {
        for (const keySub in obj[key]) {
            arrayEvent = [...arrayEvent, obj[key][keySub]];
        }
    }
    // eslint-disable-next-line consistent-return
    return arrayEvent.sort(() => Math.random() - 0.5);
}

const isMobile = ()=> {
    return !!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

const getUserData = () => {
    return Boolean(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
};

const setTownLogic = ({citiesList, setCurrentTown})=>{
    const localTown = localStorage.getItem('сity') !== null ? JSON.parse(localStorage.getItem('сity')) : false;
    const localTownExist = citiesList.find(el=> localTown.id === el.id);
    
    if(localTownExist){
        setCurrentTown(localTownExist);
    } else {
        if(citiesList.length == 1) {
            localStorage.setItem('сity', JSON.stringify(citiesList[0]));
            setCurrentTown(citiesList[0]);
        } else if(citiesList.length > 1) {
            const tempCity = citiesList.find((element) => element.name_en === 'Moscow');
            localStorage.setItem('сity', JSON.stringify(tempCity));
            setCurrentTown(tempCity);
        } else {
            localStorage.setItem('сity', JSON.stringify({id: 56, name: "Москва", name_en: "Moscow" }));
        }
    }
};

const dateToString = (string, day)=>{
    const date = new Date(string);
    const month = ['января', 'февраля', 'марта', 'апрелья', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря' ];
    const week = ['вс', 'пн', 'в', 'ср', 'чт', 'пт', 'сб' ];
    const toSymbolsHM = (any) => ((any.toString()).length < 2 ? `0${any}` : any );
    return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}${day && `, ${week[date.getDay()]}, ${toSymbolsHM(date.getHours())}:${toSymbolsHM(date.getMinutes())}`}`;
};

const sleep = ms => new Promise(r => setTimeout(r, ms));

// TODO перенести логику в effector
const switcherEvents = (typeBoard, subEventList, shuffleEventList, setAllEvents) => {
    switch(typeBoard) {
        case 'favourites': shuffleEventList && setAllEvents(shuffleEventList.filter((item)=> item.favourite)); // TODO where get favorite request
            break;
        case 'hockey': subEventList.hockey && setAllEvents(subEventList.hockey);
            break;
        case 'kids': subEventList.detjam && setAllEvents(subEventList.detjam);
            break;
        case 'festival': subEventList.festivali && setAllEvents(subEventList.festivali);
            break;
        case 'football': subEventList.football && setAllEvents(subEventList.football);  
            break;
        case 'concerts': subEventList.kontserti && setAllEvents(subEventList.kontserti);
            break;
        case 'theatre': subEventList.teatr && setAllEvents(subEventList.teatr);
            break;
        case 'sports': subEventList.sport && setAllEvents(subEventList.sport);
            break;
        default: shuffleEventList && setAllEvents(shuffleEventList);
    }
};

const Utils = {
    getIndex,
    getUserData,
    getRandomInteger,
    getRandomIndex,
    getShuffleArray,
    isMobile,
    setTownLogic,
    dateToString,
    switcherEvents,
    sleep, //for loading test Use: await sleep(1000);
};

export default Utils;