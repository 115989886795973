import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Popup from '../Popup/Popup';
import { useForm } from 'react-hook-form';
import InputForm from '../Inputs/InputForm';
import FillBtnForm from '../Buttons/FillBtnForm';
import RadioButtons from '../RadioButtons/RadioButtons';
import { TitleH4 } from '../../modules/css/CommonStyles';
import { COLORS, FONTS, TICKET_OPTIONS } from '../../config';
import CheckedButtons from '../CheckedButtons/CheckedButtons';

const SelectText = styled.span`
    color: ${COLORS.red};
    font-family: ${FONTS.regular};
`;
const Container = styled.div`
    width:100%;
    h4{
        margin: 10px 5px 10px 0;
    }

    .helper {
        display:flex;
        align-items:center;
    }
    .line {
        margin: 25px 0;
    }
    .radioButtonsContainer{
        display:flex;
        justify-content: space-around;
    }
    .checkedLineBox {
        display:flex;
        justify-content: space-between;
        margin-top: 20px; 
    }

    .placement{
        width:100%;
        display:flex;
        .placementCol{
            align-content: flex-start;
            display:flex;
            flex-wrap: wrap;
        }
        h4{
            flex-basis:100%;
        }
        .inputBox{
            flex-basis: 50%;
        }
    }

    .personal{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        align-content: flex-start;
        h4{
            flex-basis:100%;
        }
        .inputBox{
            flex-basis:33%;
            &:last-child{
                flex-basis:99%;
            }
        }
    }
`;
export default function FormSellTicket(props) {
    const hookForm = useForm();
    const { handleSubmit, formState: { isValid } } = hookForm;
    const [checkedButton, setCheckedButton] = useState([]);
    const [radioButton, setRadioButton] = useState('');

    useEffect(() => {
        setCheckedButton(TICKET_OPTIONS);
    }, []);

    const onSubmit = (data) => {
        console.log(data);
    };

    const textTypeTicket = `Электронный билет - это билет в формате PDF,\nприобретенный на официальном сайте билетного оператора.\nБумажный билет - это билет на официальном фирменном\nбланке билетного оператора, приобретенный в кассе.`;
    const textPrice = `Цена билета формируется ...`;
    const textOption = `Возможен торг - это ... . Оставлять один билет - это ... . Авто-подтверждение - это ... . `;

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="line">
                    <span className='helper'><TitleH4>Формат билета</TitleH4> <Popup text={textTypeTicket} /></span>
                    <div className="radioButtonsContainer">
                        <RadioButtons name={'typeTicket'} setType={setRadioButton} currentType={radioButton} {...{hookForm}} />
                    </div>
                </div>
                <div className="line placement">
                    <div className="placementCol">
                        <TitleH4>Размещение</TitleH4>
                        <InputForm placeholder={'Зона'} name={'zone'} isRequired={true} {...{hookForm}} />
                        <InputForm placeholder={'Сектор'} name={'sector'} isRequired={false} {...{hookForm}} />
                        <InputForm placeholder={'Кол-во билетов'} name={'count'} isRequired={false} {...{hookForm}} />
                        <InputForm placeholder={'Ряд'} name={'row'} isRequired={false} {...{hookForm}} />
                    </div>
                    <div className="placementCol">
                        <TitleH4>Места</TitleH4>
                        <InputForm placeholder={'C'} name={'from'} isRequired={false} {...{hookForm}} />
                        <InputForm placeholder={'До'} name={'to'} isRequired={false} {...{hookForm}} />
                    </div>
                </div>
                <div className="line">
                    <span className='helper'><TitleH4>Цена на витрине</TitleH4>  <Popup text={textPrice} /></span>
                    <InputForm placeholder={'Цена на витрине'} name={'price'} isRequired={false} {...{hookForm}} />
                    <p>Вы получите: <SelectText>0000₽</SelectText></p>
                </div>
                <div className="line personal">
                    <TitleH4>Персональные данные</TitleH4>
                    <InputForm placeholder={'Имя'} name={'firstName'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Номер телеофна'} name={'number'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'email'} name={'Email'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Комментарий'} name={'comment'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line">
                    <span className='helper'><TitleH4>Доп. параметры</TitleH4>  <Popup text={textOption} /></span>
                    <div className='checkedLineBox'>
                        <CheckedButtons state={checkedButton} setState={setCheckedButton} hookForm={hookForm} />
                    </div>
                </div>
                <div className="line">
                    Итого к выплате 0000₽ <FillBtnForm type="submit" title="Опубликовать" />
                </div>

            </form>
        </Container>
    );
}
