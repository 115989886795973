import { createEffect, createEvent, createStore, sample } from 'effector';
import Utils from '../../services/utils/Utils';

export const modalOpen = createEvent();
export const modalClose = createEvent();

const defaultState = {modalName:'', modalType: '', title: '', data:null};

export const $modalState = createStore(defaultState)
    .on(modalOpen, (state, payload)=> ({...state, ...payload}))
    .on(modalClose, ()=> (defaultState));

// $modalState.watch(state => console.log('$modalState', state))