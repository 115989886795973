import React, { useState } from 'react';
import OrdersOffersCard from '../../../components/OrdersOffersCard/OrdersOffersCard';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import FillBtn from '../../../components/Buttons/FillBtn';
import { MEDIA_QUERY } from '../../../config';
import styled from 'styled-components';
import { Container } from './styles';

const mockItemsSolded = [
    { title: '0', event: 'Twenty One Pilots', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022' },
    { title: '2', event: 'Twenty One Pilots', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022' }
];

const mockItemsActive = [
    { title: '0', event: 'Twenty One Pilots', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022' },
    { title: '2', event: 'Twenty One Pilots', town: 'Санкт-Петербург', place: 'Арена', date: '21.12.2022' }
];

const Buttons = styled.div`
    display:flex;
`;

export default function MyOffers() {
    const [type, setType] = useState('active');

    return (
        <Container>
            <TitleH2>Мои предложения</TitleH2> 
                <Buttons>
                    <FillBtn title="Активные" callback={()=>{setType('active')}} isActive={type == 'active'} margin={10} />
                    <FillBtn title="Проданные" callback={()=>{setType('solded')}} isActive={type == 'solded'} margin={10} />
                </Buttons>
                {type == 'active' &&
                    <OrdersOffersCard type={'active'} item={mockItemsActive[0]} />
                    // map active cards
                } 
                {type == 'solded' &&
                    <OrdersOffersCard type={'solded'} item={mockItemsSolded[0]} />
                    // map solded cards
                }
        </Container>
    );
}
