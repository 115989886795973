import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS, FONTS, TICKET_OPTIONS, MEDIA_QUERY } from '../../../config';
import CheckedButtons from '../../CheckedButtons/CheckedButtons';
import ArrowUpDown from '../../../assets/svg/arrowUpDown';
import NoFillBtn from '../../Buttons/NoFillBtn';

const marginLeft = 40;

const HiddenContent = styled.div.attrs(props => ({
        className: 'hideContent'    
        // or we can define dynamic ones
        // size: props.size || "1em",
    }))`
    ${(props) => props.isCollapsed ? 'display:none;' : ''};    
    background: ${COLORS.lightGray};
    padding: 14px;
    border-top: 1px dashed #BFBFBF;
    font-family:${FONTS.regular};
    font-weight:500;
    @media screen and ${MEDIA_QUERY.tablet} {
        margin-left: ${marginLeft}px;
    }

    .column {
        display:flex;
        border-radius:12px;
        padding:14px;
        flex-direction: row;
        justify-content: space-between;

        & .marginLeftConflux {
            .column {
                padding:0;
            }
        }

        &.firstBlockGray {
            flex-direction: row;
            color:${COLORS.white};
            text-transform: uppercase;
            background: ${COLORS.semiGray};
            & span:nth-child(1){
                color:${COLORS.white};
            }
        }

        @media screen and ${MEDIA_QUERY.tablet} {
            justify-content: flex-start;
            flex-direction: column;
            &.firstBlockGray {
                flex-direction: column;
                text-transform: none;
            }
        }
    }

    .purchase{
        & .firstBlockGray {
            color:${COLORS.white};
            background: ${COLORS.darkGray};
        }
    }

    // .column{
    //     z-index:2;
    //     display:flex;
    //     border-radius:12px;

    //     padding:14px;
    //     flex-direction: column;
    //     justify-content: space-between;

    //     & .basis150 {}

    //     &.firstBlockGray {
    //         background: ${COLORS.semiGray};
    //         color:${COLORS.white};
    //         flex-direction: column;
    //         text-transform: none;
    //         & span:nth-child(1){
    //             color:${COLORS.white};
    //         }
    //     }

    //     &.lastBlock{
    //         color: ${COLORS.green};
    //     }

    //     & span:nth-child(1){
    //         color:${COLORS.semiGray};
    //     }

    //     & span:nth-child(2){
    //         font-family:${FONTS.bold};
    //     }
    
    //     @media screen and ${MEDIA_QUERY.tablet} {
    //         padding: 26px;
    //         flex-direction: row;

    //         &.firstBlockGray{
    //             flex-basis: 80px;
    //         }

    //         & .marginLeftConflux {
    //             margin-left: -10px;
    //             padding-left: 30px;
    //             z-index:1;
    //         }

    //         & span{
    //             margin-right: 50px;
    //         }
    //     }
    // }
    
    // .purchase{
    //     .column{
    //         flex-direction: row;
    //         justify-content: space-between;
    //         text-transform: uppercase;

    //         span{
    //             margin:0;
    //         }
    //         &.firstBlockGray{
    //             background: ${COLORS.darkGray};
    //         }
    //     }
    //     @media screen and ${MEDIA_QUERY.tablet} {
    //         .column{
    //             padding: 14px;
    //             flex-direction: column;
    //             justify-content: flex-start;
    //             text-transform: none;
    //             span{
    //                 margin:0;
    //             }
    //         }   
    //     }
    // }
`;

const Line = styled.div`
    display:flex;
    border-radius: 12px;
    margin-bottom:10px;
    ${props => props.spaced && `justify-content: space-between; padding: 0 10px;`}
    ${props => props.background ? `background: ${props.background};` : ''}
    ${props => props.removePadding ? `
        div {
            padding: 10px 0;
        }
    ` : ''}
    
    flex-direction: column;
    @media screen and ${MEDIA_QUERY.tablet} {
        flex-direction: row;
        ${props => props.isIn ? `justify-content: space-between;` : ''}
        width: 100%;
    }
`;

const Purchase = styled.div`
    ${(props) => props.isCollapsed ? 'display:none;' : ''}; 
`;


const RadioButton = styled.a`
    backgroud:'#faf';
    display:flex;
    padding: 5px;
    align-items: 'center';
    justify-content: 'center';
    & svg {
        margin-right:10px;
    }
`;

const ListButton = styled.div`
    display: flex;
    border-radius: 12px;
    padding-left:10px;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.white};
`;

const HiddenButton = styled.a`

    height:100%;
    border:none;
    display:flex;
    font-size: 16px;
    background: none;
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.medium};
    ${props => props.isActive
        ? `color:${COLORS.white};
            background-color: ${COLORS.red};`
        : `color:${COLORS.semiGray};`
    }
    
`;

//TODO rechange burger component
const Burger = (props) => {
    const Container = styled.div`
        width:100%;
        margin: 10px 0;
        display:flex;
        padding:10px;
        align-items: center;
        font-weight: 600;
        background: ${COLORS.white};
        font-family:${FONTS.regular};
        border-radius: 12px;
        justify-content: space-between;

        @media screen and ${MEDIA_QUERY.tablet} {
            width:150px;
            margin: 10px;
        }
    `;
    const Burger = styled.button`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 23px;
        height: 16px;
        padding: 0;
        border: none;
        background: none;
        &>div{
            width: 100%;
            height: 2px;
            background-color: ${COLORS.red};
        }
    `;
    return (
        <Container>
            <span>{props.title}</span>
            <Burger><div></div><div></div><div></div></Burger>
        </Container>
    )
};

////////////////////// moks  TODO delete after add backend <
const itemsCurrentOrders = [
    {
        id: 111112022,
        zone: 'Западный вход',
        sector: 'Сектор A4',
        row: 1,
        place: '10-17',
        price: 10000,
        money: 8000,
    }
]

const itemsCurrentOrdersComplete = [
    {
        id: 2111112022,
        countSolded: 2,
        place: '0,1',
        price: 10000,
        total: 20000,
        status: 'Отправлен',
    },
    {
        id: 2111112023,
        countSolded: 2,
        place: '0,1',
        price: 10000,
        total: 20000,
        status: 'Отправлен',
    }
]

const itemsCurrentOrdersRequest = [];

////////////////////// moks  TODO delete after add backend >

export default function CardHideContent(props) {
    const { isCollapsed, type } = props;
    const [offers, setOffers] = useState(itemsCurrentOrders);
    const [checkedButton, setCheckedButton] = useState([]);
    const [isCollapsedPurchase, setIsCollapsedPurchase] = useState(false);
    const [listInHide, setListInHide] = useState(type == 'active' ? itemsCurrentOrdersRequest : itemsCurrentOrdersComplete);

    const onSelectChange = (item, i)=>{
            console.log(checkedButton);
            let tempArray = checkedButton;
            item['isChecked'] = !item.isChecked;
            tempArray[i] = item;
            setCheckedButton([...tempArray]);
    };

    useEffect(()=>{
        setCheckedButton(TICKET_OPTIONS);
    },[]);

    return (
        <>
            <HiddenContent isCollapsed={isCollapsed}>

                <Line>
                    <div className={"column basis150"} basis={150}></div>
                    <Burger title={'Зона'} />
                    <Burger title={'Сектор'} />
                </Line>

                {offers.map((item, index) => (
                    <Line key={index} background={COLORS.white}>
                        <div className={"column firstBlockGray"}>
                            <span>ID предложения</span>
                            <span>{item.id}</span>
                        </div>
                        <div>
                            <Line isIn>
                                <div className={"column marginLeftConflux"}>
                                    <span>Зона</span>
                                    <span>{item.zone}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Сектор</span>
                                    <span>{item.sector}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Ряд</span>
                                    <span>{item.row}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Номер места</span>
                                    <span>{item.place}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Цена на витрине</span>
                                    <span>{item.price}₽</span>
                                </div>
                                <div className={"column lastBlock"}>
                                    <span>Получу за билет</span>
                                    <span>{item.money}₽</span>
                                </div>
                            </Line>
                            {type == 'active' &&
                                <Line spaced>
                                    <CheckedButtons state={checkedButton} setState={setCheckedButton} />
                                </Line>
                            }
                        </div>
                    </Line>
                ))}

                <Line spaced>
                    <ListButton>
                        <p>{type == 'active' ? 'Запросов на покупку' : 'Завершенные покупки'}</p>
                        <HiddenButton isActive={listInHide.length>0} onClick={() => { setIsCollapsedPurchase(!isCollapsedPurchase); }}>
                            {listInHide.length} &nbsp;<ArrowUpDown color={listInHide.length>0 ? COLORS.white : false} isUp={!isCollapsedPurchase} />
                        </HiddenButton>
                    </ListButton>
                    {type == 'active' && <NoFillBtn color={COLORS.semiGray} textValue="Удалить" callback={() => { console.log('Удалить'); }} />}
                </Line>

                <Purchase className={"purchase"} isCollapsed={isCollapsedPurchase}>
                    {listInHide.map((item, index) => (
                        <Line key={index} background={COLORS.white}>
                            <div  className={"column firstBlockGray"}>
                                <span>ID предложения </span>
                                <span>{item.id}</span>
                            </div>
                            <Line isIn>
                                <div className={"column marginLeftConflux"}>
                                    <span>Продано</span>
                                    <span>{item.countSolded}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Места</span>
                                    <span>{item.place}</span>
                                </div>
                                <div className={"column"}>
                                    <span>Цена за 1 билет</span>
                                    <span>{item.price}₽</span>
                                </div>
                                <div className={"column"}>
                                    <span>Итого</span>
                                    <span>{item.total}₽</span>
                                </div>
                                <div className={"column"}>
                                    <span>Статус</span>
                                    <span>{item.status}</span>
                                </div>
                            </Line>
                        </Line>
                    ))}
                </Purchase>

            </HiddenContent>
        </>
    );
}
