import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { addToFavorite } from '../../../modules/effector/events';
import OnlyEticket4 from '../../../assets/svg/onlyEticket4';
import FavouriteSvg from '../../../assets/svg/favouriteSvg';
import { COLORS, MEDIA_QUERY } from '../../../config';
import Utils from '../../../services/utils/Utils';
import { CATEGORIES } from '../../../config';
import HooksApi from '../../../API/hooksApi';
import ChipList from '../../Chip/ChipList';

const Wrapper = styled.div`
	position: relative;
	@media screen and ${MEDIA_QUERY.laptop} {
		display: block;
		width: 32%;
	}
	.favorite {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 21;
		display:flex;
		padding: 10px;
		border-radius:50px;
		background-color: #fff;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
`;
//TODO favorite 
const CardContainer = styled.div`
	display: block;
	position:relative;
	.favorite {
		display:flex;
		align-items: center;
		align-content: center;
		justify-content:center;
		right: 10;
		width:50px;
		height:50px;
		z-index: 21;
		background:#fff;
		position: absolute;
		border-radius: 50px;
	}
`;

const Banner = styled.div`
	box-sizing: border-box;
	position: relative;
	width: 100%;
	height: 28vh;

	.dark-bg {
		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		padding: 12px;
		height: 100%;
		z-index: 10;
		border-radius: 22px;
		background-color: rgba(0, 0, 0, 0.4);
	}

	& div {
		z-index: 20;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 12px;
		z-index: 10;
	}
`;

const InfoEvent = styled.div`
	width: 100%;
	margin-top: 5px;

	& .name {
		font-size: 18px;
		line-height: 20px;
		color: ${COLORS.black};
		font-weight: 600;

		& span {
		font-weight: 400;
		}
	}

	& .place {
		font-size: 13px;
		line-height: 22px;
		color: ${COLORS.darkGray};
	}
`;

const Label = styled.div`
	position: absolute;
	top: -5px;
	left: -5px;
	width: 100px;
	height: 100px;
`;

export default function CardEvent(props) {
	const { href, event } = props;
	const { start, arenaName, yearsTxt, name, minShowroomPrice, category, favourite } = event;
	const { color, dataBaseName } = CATEGORIES.find((element) => element.category === category);
	const date = dayjs(start).format('D MMMM YYYY');
	const [srcImg, setSrcImg] = useState('');
	const [isFavorite, setIsFavorite] = useState(favourite);
	const userData = Utils.getUserData();
	
	useEffect(() => {
		HooksApi.getImageForEvent(event.event_id).then((data) => setSrcImg(data));
	}, [setSrcImg]);

	const addToFavoriteFun = (id)=> {
		if(userData?.id){
			HooksApi.favoriteToggle(id).then(() => {
				addToFavorite({id: id, dbName: dataBaseName});
				setIsFavorite(!isFavorite);
			});
		} else {
			setIsFavorite(!isFavorite);
		}
	};

	return (
		<Wrapper>
			<a className='favorite' onClick={()=>{addToFavoriteFun(event.event_id)}}><FavouriteSvg isFav={isFavorite} /></a>
			<Link to={href}>
				<CardContainer>
					<Banner>
						{srcImg && <img src={srcImg} loading="lazy" alt="" />}
						<div className="dark-bg">
							<ChipList cost={minShowroomPrice} color={color} categoryName={category} />
							<Label><OnlyEticket4 color={color} /></Label>
						</div>
					</Banner>
					<InfoEvent>
						<div className="name">{name}{' '}<span>{yearsTxt}</span></div>
						<div className="place"><span>{date}</span><span>{' '}•{arenaName}</span></div>
					</InfoEvent>
				</CardContainer>
			</Link>
		</Wrapper>
	);
}
