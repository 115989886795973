import React, { useEffect, useRef, useState } from 'react';
import { TitleH2, Text } from '../../modules/css/CommonStyles';
import { setFilterEvents } from '../../modules/effector/events';
import { modalOpen, modalClose } from '../../modules/effector/modal';
import FillBtn from '../../components/Buttons/FillBtn';
import { MEDIA_QUERY, COLORS } from '../../config';
import BtnClose from '../Buttons/BtnClose';
import styled from 'styled-components';
import Radio from '../radio/radio';

//doc swiper https://swiperjs.com/demos#centered-auto

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const Wrapper = styled.section`
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	padding-bottom: 10px;

	& > * {
		margin-top: 0;
	}

	& input  {
		width: 100%!important;
	}

	& .prefix {
		left: 15px!important;
	}

	& .prefix {
		color: ${COLORS.black}!important;
	}

	& .title-search {
		font-size: 22px;
		line-height: 24px;
		text-align: center;
		font-weight: bold;
		margin-bottom: 20px;
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		max-width: 890px;
		margin: 0 auto;
		& .title-search {
			font-size: 46px;
			line-height: 54px;
			text-align: center;
			font-weight: bold;
			margin-bottom: 50px;
		}
	}
`;

const Modal = styled.div`
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	background-color: ${COLORS.white};
	height: 100vh;
	padding: 20px;
	z-index: 40;
	transform: translateX(-100%);
	transition: all .5s ease;
	
	&.open {
		transform: translateX(0);
	}
	
	.close-btn {
		position: absolute;
		top: 25px;
		right: 20px;
	}
`;

const InputBox = styled.div`
	position: relative;
		a {
			position: absolute;
			display: flex;
			align-items: center;
			top: 5px;
			right: 0;
			height: 80%;
			background-color: transparent;
			box-shadow: none;
			border-left: 1px solid ${COLORS.black};
		}

		@media screen and ${MEDIA_QUERY.laptop} {
			width: 100%!important;
		}
`;

const Input = styled.input`
	box-sizing: border-box!important;
	position: relative;
	display: block;
	padding: 13px 90px 13px 50px!important;
	border-radius: 32px!important;
	border: 1px solid ${COLORS.black}!important;
	background-color: ${COLORS.white};
	margin: 0!important;
	box-shadow: none!important;
	::placeholder {
		color: ${COLORS.black};
		font-size: 0.9rem;
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		max-width: 890px;
		margin: 0 auto!important;
		width: 100% !important;
	}
`;

const SwiperWrapper = styled.section`
    width:100%;
    padding:20px 0;
    .swiper-slide {
        display: block;
        width: 260px;
        height: 100%;
        object-fit: cover;
    }
`;

export default function SearchBlock(props) {
	const { setInput } = props;
	const modalEl = useRef(null);
	const [currentOption, setCurrentOption] = useState();
	const options = [
		{id:'near', title:'Ближайшие'},
		{id:'date', title:'Выбрать дату'},
		{id:'pop', title:'Популярные'},
		{id:'eticket', title:'Только на Eticket4'},
	];

	const toggleIsOpenHandler = (evt) => {
		evt.preventDefault();
		modalEl.current.classList.toggle('open');
	};

	const handlerOptions = (id)=>{
		if(currentOption == id){
			setCurrentOption(undefined)
		} else {
			switch(id) {
				case "near": nearestDate(id);
					break;
				case "date": selectDate(id);
					break;
				case "pop": popular(id);
					break;
				case "eticket": onlyEticket(id);
					break;
				default: resetSetting();
			}
		}
	};

	const resetSetting = () =>{
		// setFilterEvents({option:'', data:''})
	};

	const nearestDate = (id) => {
		setCurrentOption(id);
		setFilterEvents({option:'nearDate', data:'CurrentData'});
	};

	const selectDate = (id) => {
		//TODO logical modal select date if succes set filter
		const selectFn = (date)=>{
			setFilterEvents({option:'date', data: { date }});
			setCurrentOption(id);
			modalClose();
		};
		modalOpen({modalName:'DateRangeModal', modalType:'сhooseDate', data: { selectFn }});
	};

	const popular = (id) => {
		setCurrentOption(id);
		setFilterEvents({option:'popular', data:''})
	};

	const onlyEticket = (id) => {
		setCurrentOption(id);
		setFilterEvents({option:'onlyEticket', data:''})
	};

	return (
		<Wrapper>
		{props.title && <TitleH2 textAlign='center'>{props.title}</TitleH2> }
		{props.subTitle && <Text textAlign='center'>{props.subTitle}</Text> }
		<form method="POST" action="">
			<InputBox>
				<Input placeholder={props.placeholder ? props.placeholder : "Поиск"} icon="search" onChange={(e) => setInput(e.target.value) } />
			</InputBox>
			<Modal ref={modalEl}> {/* TODO check */}
				<BtnClose callback={toggleIsOpenHandler} />
				<TitleH2>Фильтры</TitleH2>
				<Radio />
			</Modal>
		</form>
		{options.length > 0 && 
			<SwiperWrapper>
					<Swiper
						freeMode={true}
						spaceBetween={10}
						modules={[FreeMode]}
						className="mySwiper"
						slidesPerView={"auto"}
						breakpoints={{
							930: { slidesPerView: 4 }
						}}
					>
						{options.map((item, index)=>(
							<SwiperSlide key={`SwiperSlide${index}`}>
								<FillBtn title={item.title} callback={()=>{handlerOptions(item.id)}}  isActive={currentOption == item.id} isGray />
							</SwiperSlide>
						))}
					</Swiper>
			</SwiperWrapper>
		}
		</Wrapper>
	);
}
