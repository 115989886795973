import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import FillBtnForm from '../../../components/Buttons/FillBtnForm';
import { resetAuthData } from '../../../modules/effector/auth';
import NoFillBtn from '../../../components/Buttons/NoFillBtn';
import { modalOpen } from '../../../modules/effector/modal';
import ModalTemplateHeader from '../ModalTemplateHeader';
import ModalTemplateFooter from '../ModalTemplateFooter';
import { MEDIA_QUERY, COLORS } from '../../../config';
import AuthService from '../../../API/authApi';

const WrapperSignInByLoginView = styled.form`
	width: 100%;
	margin: 0 auto;

	@media screen and ${MEDIA_QUERY.mobileL} {
		width: 344px;
	}

	.input-field {
		margin: 0;
	}
	.more-btn{
		width: 100%;
		margin-bottom: 56px;
		&:last-child {
		margin-bottom: 24px; 
		}
	}
	#emailInAuthModalSignInId {
		margin-bottom: 12px !important;
	} 
	#passInAuthModalSignInId {
		margin-bottom: 19px !important;
	}
	input {
		box-sizing: border-box!important;
		position: relative;
		display: flex !important;
		padding: 14px 15px 16px !important;
		border-radius: 12px!important;
		border: 1.5px solid ${COLORS.black} !important;
		background-color: ${COLORS.white};
		margin: 0!important;
		box-shadow: none!important;
		height: auto !important;
		font-size: 18px !important;  
		line-height: 22px;
		letter-spacing: 0.02em;
		@media screen and ${MEDIA_QUERY.laptop} {
			max-width: 890px;
			margin: 0 auto!important;
			width: 100% !important;
		}
	}
	.isValid {
		border-color: ${COLORS.red} !important;
	}
`;

export default function SignInByLoginView(props) {
	const { register, formState: { errors, isValid, }, handleSubmit, setError, reset, } = useForm({ mode: 'all', });
	//TODO effector form

	const forgotPassword = ()=>{
		modalOpen({modalName:'AuthModal', modalType:'sendLinkView'});
	};

	const onPhoneAuth = ()=>{
		modalOpen({modalName:'AuthModal', modalType:'phoneView'});
		resetAuthData();
	};

	const sendAuthInfo = (data) => {
		AuthService.signInByLogin(data.email, data.password).then((payload) => {
			if(payload){
				localStorage.setItem('user', JSON.stringify(payload?.data));
				reset();
				document.location.reload();
			} else {
				setError('apiError', { message: 'Неверный логин или пароль' });
			}
		}).catch((error) => {
			console.log(error);
			setError('apiError', { message: 'Неверный логин или пароль' });
		});
	};

	return (
		<>
		{/* <ModalTemplateHeader title="Вход по почте" onCloseCallBack={onCloseCallBack} /> */}
		<ModalTemplateHeader title="Вход по почте" />
		<WrapperSignInByLoginView onSubmit={handleSubmit(sendAuthInfo)}>
			<input
				className={errors?.email ? 'isValid' : null}
				placeholder="Email"
				type="email"
				icon="none"
				id="emailInAuthModalSignInId"
				{...register('email', {
					required: 'Поле обязательно для заполнения!',
				})}
			/>
			{errors?.email && <p style={{ color: COLORS.red, marginBottom: '20px' }}>{errors?.email?.message || 'Error!'}</p>}
			<input
				className={errors?.password ? 'isValid' : null}
				placeholder="Пароль"
				type="password"
				icon="none"
				id="passInAuthModalSignInId"
				{...register('password', {
					required: 'Поле обязательно для заполнения!',
					minLength: {
					value: 6,
					message: 'Минимум 6 символов!',
					},
				})}
			/>
			{errors?.password && <p style={{ color: COLORS.red, marginBottom: '20px' }}>{errors?.password?.message || 'Error!'}</p>}
			{errors.apiError && <span style={{ color: COLORS.red, marginBottom: '20px' }} onClick={forgotPassword}>Востанновить?</span>}
			<FillBtnForm title="Войти" isValid={!isValid} disabled={!isValid} />
			<NoFillBtn textValue="ВОЙТИ ПО ТЕЛЕФОНУ" callback={onPhoneAuth} id="phoneView" />
		</WrapperSignInByLoginView>
		<ModalTemplateFooter />
		</>
	);
}
