import React from "react";

const ArrowUpDown = (props) => {
    return (
        props.isUp
            ? <svg className={props.className} onClick={props.onCLick} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2997 9.1474C14.6939 9.53419 15.327 9.52816 15.7138 9.13393C16.1006 8.73971 16.0946 8.10657 15.7003 7.71979L14.2997 9.1474ZM8 1.56567L8.70034 0.85186C8.31142 0.470271 7.68859 0.470271 7.29966 0.851859L8 1.56567ZM0.29966 7.71978C-0.0945663 8.10657 -0.100596 8.73971 0.286192 9.13393C0.672979 9.52816 1.30612 9.53419 1.70034 9.1474L0.29966 7.71978ZM15.7003 7.71979L8.70034 0.85186L7.29966 2.27948L14.2997 9.1474L15.7003 7.71979ZM7.29966 0.851859L0.29966 7.71978L1.70034 9.1474L8.70034 2.27948L7.29966 0.851859Z" fill={props.color ? props.color : "#9F9F9F"}/>
            </svg>
            :<svg className={props.className} onClick={props.onCLick} width="16" height="10" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2672 0.319549C13.643 -0.0851618 14.2757 -0.108596 14.6805 0.267206C15.0852 0.643009 15.1086 1.27574 14.7328 1.68045L13.2672 0.319549ZM7.5 8L8.23279 8.68045C8.04358 8.88422 7.77807 9 7.5 9C7.22193 9 6.95642 8.88422 6.76721 8.68045L7.5 8ZM0.267206 1.68045C-0.108597 1.27574 -0.0851622 0.643011 0.319549 0.267208C0.724259 -0.108595 1.35699 -0.0851607 1.73279 0.31955L0.267206 1.68045ZM14.7328 1.68045L8.23279 8.68045L6.76721 7.31955L13.2672 0.319549L14.7328 1.68045ZM6.76721 8.68045L0.267206 1.68045L1.73279 0.31955L8.23279 7.31955L6.76721 8.68045Z" fill={props.color ? props.color : "#9F9F9F"}/>
            </svg>
            
    );
}

export default ArrowUpDown;