import React, { Suspense } from 'react';

const Layout = React.lazy(() => import('./LazyLayout'));

export default function LoadingLayout() {
  return (
    <Suspense fallback={<div>Загрузка...</div>}>
      <Layout />
    </Suspense>
  );
}
