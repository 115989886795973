import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from "effector-react"
import { $shuffleEventList, $subEventList} from '../../modules/effector/events';
import MainCardSlider from '../Cards/MainSliderCard/MainCardSlider';
import { MEDIA_QUERY, COLORS } from '../../config';
import Utils from '../../services/utils/Utils';
import BtnSlider from '../Buttons/BtnSlider';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import 'swiper/css';

const Wrapper = styled.section`
	position: relative;
	width: 100%;
	padding: 20px;
	padding-bottom: 10px;

	.swiper-slide {
		width: 100%!important;
	}
	
	.dark-bg {
		position: relative;
		z-index: 10;
		border-radius: 20px;
		background-color: rgba(0, 0, 0, 0.4);
	}

	.swiper-slide img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 20px;
	}
	
	& .eventInfo {
		position: relative;
		z-index: 20!important;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0 15px 40px 15px;
		margin-top: auto;
		height: 50vh;

	}

	& .eventInfo__name {
		font-weight: 600;
		font-size: 20px;
		line-height: 20px;
		color: ${COLORS.white};
	}
	
	& .eventInfo__yo {
		font-size: 14px;
		font-weight: normal;
	}
	
	.eventInfo__place {
		margin-bottom: 10px;
		font-size: 13px;
		line-height: 22px;
		color: #CBCBCB;
	}
	
	.eventBtn {
		position: absolute;
		top: calc(50% - 20px);
		z-index: 30;
	}
	
	.eventBtn--prev {
		left: 10px;
		transform: rotate(180deg);
	}

	.eventBtn--next {
		right: 10px;
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		.swiper-slide {
		width: 33%!important;
		}
	}
;`;

export default function EventSlider(props) {
	const { typeBoard } = props;
	const [padding, setPadding] = useState(20);
	const [slides, setSlides] = useState(1);
	const [events, setEvents] = useState([]);
	const shuffleEventList = useStore($shuffleEventList);
	const subEventList = useStore($subEventList);
	const { switcherEvents } = Utils;

	useEffect(() => {
		if(shuffleEventList.length > 0){
			setEvents(shuffleEventList);
		}
	}, [shuffleEventList]);

	useEffect(() => {
		switcherEvents(typeBoard, subEventList, shuffleEventList, setEvents);
	},[typeBoard, subEventList, shuffleEventList])

	useEffect(() => {
		if (window.innerWidth > 1140) {
			setPadding(30);
			setSlides(3);
		}
	}, [window.resize]);

	return (
		<Wrapper>
			<Swiper
				spaceBetween={padding}
				slidesPerView={slides}
				centeredSlides
				centeredSlidesBounds
				loop
				navigation
			>
				{events.length > 0 && events.map((event) => (
					<SwiperSlide key={event.event_id}>
					<Link to={`/event/${event.event_id}`}>
						<MainCardSlider data={event} />
					</Link>
					</SwiperSlide>
				))}
				<BtnSlider isNext={false} className="eventBtn eventBtn--prev" />
				<BtnSlider isNext className="eventBtn eventBtn--next" />
			</Swiper>
		</Wrapper>
	);
}
