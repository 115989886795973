import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../config';
import QuestionIcon from '../../assets/svg/questionIcon';

// ${props => props.isProp ? `` : `32px`};

//TODO onMobile popup
const Container = styled.div`
    .ContainerPopup {
        display:none;
    }
    .IconContainer:after{
        content:none;
    }
    &:hover {
        .ContainerPopup {
            display:block;
        }
        .IconContainer:after{
            content:'';
        }
    }
`;

const IconContainer = styled.div`
    position: relative;
    &:after {
        content:'';
        border-style: solid;
        border-width: 10px 10px 15px 10px;
        border-color: transparent transparent ${COLORS.lightGray} transparent;
        position: absolute;
        top: 15px;
        left: 4px;
    }
`;

const ContainerPopup = styled.div`
    position:absolute;
    background: ${COLORS.lightGray};
    padding:11px;
    border-radius:12px;
`;

export default function Popup(props) {
    const { text } = props;

	return (
		<Container>
            <IconContainer className='IconContainer'>
                <QuestionIcon />
            </IconContainer>
            <ContainerPopup className='ContainerPopup'>
                {text}
            </ContainerPopup>
		</Container>
	);
}
