import React, { useState, useEffect } from 'react';
import { TitleH2, TitleH3, SubText2 } from '../../modules/css/CommonStyles';
import HooksApi from '../../API/hooksApi';
import CardInfoIcon from '../../components/Cards/CardInfo/CardInfo';
import FormSellTicket from '../../components/Forms/FormSellTicket';
import Utils from '../../services/utils/Utils';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display:flex;
    padding:20px;
    margin: 0 auto;
    max-width:1500px;
`;

const ContainerForm = styled.div`
    width:66%;
    padding-right:100px;
`;

const ContainerSideContent = styled.div`
    width:34%;
`;

const CardEventInfo = styled.div`
    img {
        border-radius:24px;
        width:100%;
    }
`;

export default function SellTicketEvent() {
    const { id } = useParams(); // use for request
    const [srcImg, setSrcImg] = useState('');
    const [eventDetail, setEventDetail] = useState(undefined);
    const { dateToString } = Utils;

    useEffect(()=>{
        if(id){
            HooksApi.getCurrentEvent(id).then((data)=>{ setEventDetail(data.eventDetail); console.log(data.eventDetail)});
            HooksApi.getImageForEvent(id).then((data)=>{ setSrcImg(data)});
        }
    },[id]);



	return (
		<Container>
            <ContainerForm>
                <TitleH2>Продать билеты</TitleH2>
                <FormSellTicket />
            </ContainerForm>
            <ContainerSideContent>
                {eventDetail && <CardEventInfo>
                    <img class="img" src={srcImg} alt={eventDetail.name} />
                    <TitleH3>{eventDetail.name}</TitleH3>
                    <SubText2>{dateToString(eventDetail.start)}  • {eventDetail.arena_name}</SubText2>
                </CardEventInfo>}
                <CardInfoIcon type={'ApprovalHand'} />
                <CardInfoIcon type={'CardInCircle'} />
            </ContainerSideContent>
		</Container>
	);
}
