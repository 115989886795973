import React, { useState } from 'react';
import styled from 'styled-components';
import ModalTemplateHeader from '../ModalTemplateHeader';
import FillBtn from '../../Buttons/FillBtn';
import { DateRangePicker } from 'react-date-range';
import BaseInput from '../../Inputs/BaseInput';
import AuthService from '../../../API/authApi';
import { useStore } from "effector-react";
import { $modalState } from "../../../modules/effector/modal";

const ViewText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  .dateRangePick{
    .rdrDefinedRangesWrapper {
      display:none;
    }
  }
`;

const selectionRangeStart = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}

export default function ChooseDate() {
  const [selectData, setSelectData] = useState(selectionRangeStart);
	const modalState = useStore($modalState);

	

	const handleSelect = (handleData)=>{
    console.log({handleData});
    const data = {
      startDate: handleData.selection.startDate,
      endDate: handleData.selection.endDate,
      key: 'selection',
    }
    setSelectData(data);
    // // return data
	};

	const setDateRange = ()=>{
		modalState.data.selectFn(selectData);
	};

	return (
		<>
			<ModalTemplateHeader title="Выберете дату" />
			<ViewText>
				<DateRangePicker
          className={'dateRangePick'}
					ranges={[selectData]}
					onChange={handleSelect}
				/>
			</ViewText>
			<FillBtn isActive title="Выбрать дату" callback={setDateRange} />
		</>
	);
}
