import React from 'react';
import { Container } from './styles';
import { TitleH2, TitleH3, Text } from '../../../modules/css/CommonStyles';

export default function Guarantee() {
    return (
        <Container>
            <TitleH2>Гарантии</TitleH2>
            <TitleH3>Покупателям</TitleH3>
            <Text>
                Продавец получает оплату за реализованные билеты только после того, как покупатель посетит мероприятие.
                Таким образом, мы гарантируем покупателям оригинальность и соответствие купленных билетов предложению,
                которое было опубликовано на сайте. Кроме того, все продавцы указывают данные кредитной карты в качестве
                дополнительной гарантии.
            </Text>
            <TitleH3>Продавцам</TitleH3>
            <Text>
                Продавец получает гарантию оплаты за свои билеты. Мы списываем средства с карты покупателя после продажи билетов
                и перечисляем ее на счет продавца в точные сроки. Без оплаты со стороны покупателя билеты не будут проданы.
            </Text>
        </Container>
    );
}
