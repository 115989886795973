import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-materialize'; //TODO replace materialize
import CategoryCard from './../Cards/CategoryCard/CategoryCard';
import { $categoryList } from '../../modules/effector/events';
import { CATEGORIES } from '../../config';
import { useStore } from 'effector-react';

export default function CategorySlider() {
	const [padding, setPadding] = useState(10);
	const [widthPredicate] = useState(true);
	const categoryList = useStore($categoryList);
	const [startCategories, setStartCategories] = useState(CATEGORIES);

	useEffect(() => {
		if(categoryList.length > 0) {
			const tempArray = [];
			startCategories.forEach(item=>{
				categoryList.forEach((serverItem)=>{
					if(item.category == serverItem.name){
						tempArray.push({...item, count: serverItem.count });
					}
				})
			});
			setStartCategories(tempArray);
		}
	}, [categoryList]);

	useEffect(() => {
		if (window.innerWidth > 1140) {
			setPadding(60);
		}
	}, [window.resize]);

	return (
		<Carousel
			carouselId="2"
			options={{
				fullWidth: widthPredicate,
				indicators: false,
				padding,
			}}>
		{startCategories.map((category) => (
			<div key={category.key}>
				<CategoryCard categoryInfo={category} />
			</div>
		))}
		</Carousel>
	);
}
