import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import InputForm from '../Inputs/InputForm';
import FillBtnForm from '../Buttons/FillBtnForm';
import { MEDIA_QUERY, FONTS, COLORS } from '../../config';
import AuthService from '../../API/authApi';

const Container = styled.div`
    width: 100%;
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .line1, .line2, .line3, .line4{
        flex-direction: column;
        width:100%;
        display:flex;
        margin: 0;
    }
    @media screen and ${MEDIA_QUERY.tablet} {
        form{
            align-items: flex-start;
        }
        .line1, .line2, .line3, .line4{
            flex-direction: row;
        }
		.line1{
            .inputBox:nth-child(1) {
                width:40%;
            }
            .inputBox:nth-child(2) {
                width:27%;
            }
            .inputBox:nth-child(3) {
                width:33%;
            }
        }
        .line2 {
            .inputBox:nth-child(1) {
                width:67%;
                
            }
            .inputBox:nth-child(2) {
                width:33%;
            }
        }
        .line3{
            .inputBox:nth-child(1) {
                width:27%;
            }
            .inputBox:nth-child(2) {
                width:40%;
            }
            .inputBox:nth-child(3) {
                width:33%;
            }
        }
        .line4{
            .inputBox {
                width:100%;
            }
        }
	}
`;
//TODO remove materialize? need !important

export default function FormPersonalData() {
    const hookForm = useForm();
    const { handleSubmit, setValue, reset } = hookForm;

    const onSubmit = (data) => {
        //TODO 
        AuthService.userMeSet({
            email: data.email,
            surname: data.lastName,
            name: data.firstName,
            patronymic: data.middleName,
            country: data.country,
            city: data.city,
            zipCode: data.index,
            phone: data.number,
            address: data.address,
        })
    };

    useEffect(() => {
        AuthService.userMeInfo().then(data=>{
            const userData = data.data;
            if(data.status == 200){
                reset({
                    firstName: userData.name,
                    lastName: userData.surName,
                    middleName: userData.patronymic,
                    email: userData.login,
                    number: userData.phone,
                    country: userData.country,
                    city: userData.city,
                    index: userData.zipCode,
                    address: userData.address,
                });
            } // TODO check add error render??
        });
    }, []);

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="line1">
                    <InputForm placeholder={'Фамилия'} name={'firstName'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Имя'} name={'lastName'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Отчество'} name={'middleName'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line2">
                    <InputForm placeholder={'Электронная почта'} name={'email'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Номер'} name={'number'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line3">
                    <InputForm placeholder={'Страна'} name={'country'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Город'} name={'city'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Индекс'} name={'index'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line4">
                    <InputForm placeholder={'Адрес проживания'} name={'address'} isRequired={false} {...{hookForm}} />
                </div>
                <FillBtnForm type="submit" title="Сохранить" />
            </form>
        </Container>
    );


}