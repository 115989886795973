import React from 'react';
import { MEDIA_QUERY, COLORS, FONTS } from '../../config';
import CheckBox from '../../assets/svg/CheckBox';
import styled from 'styled-components';


const CheckedButton = styled.a`
    display:flex;
    padding-right:20px;
    align-items: center;
    & svg {
        margin-right:10px;
    }
    p {
        margin:0;
    }
    input[type=checkbox]{
        // display:none;
    }
    color:${COLORS.black};

    margin: 5px 0;
    @media screen and ${MEDIA_QUERY.tablet} {
        margin: 0;
    }
`;


export default function CheckedButtons(props) {
    const { register, setValue } = props.hookForm || { register:false, setValue:false };

    const onSelectChange = (item, i)=>{
        let tempArray = props.state;
        item['isChecked'] = !item.isChecked;
        tempArray[i] = item;
        props.setState([...tempArray]);
    };

	return (
		<>
            {props.state.map((item, index) => {
                if(Boolean(setValue)) setValue(item.type, item.isChecked);
                const onClick = ()=> { onSelectChange(item, index); };

                return (
                    <CheckedButton onClick={onClick} key={`${item.type}${item.isChecked}`}>
                        <CheckBox isRound={true} isChecked={item.isChecked} />
                        { register && <input type="checkbox" id={`checkedButtonIndex${index}`} name={item.type} {...register(item.type, { required: false })}/> }
                        <p>{item.text}</p>
                    </CheckedButton>
                )
            })}
        </>
	);
}
