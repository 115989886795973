import axios from 'axios';
import noImg from '../assets/img/noImg.svg';
import { LINKS } from '../config';
import Utils from '../services/utils/Utils';

class HooksApi {
    static async getEventMainPage() {
		const userData = Utils.getUserData();
        const response = await axios.get(`${LINKS.MAIN_ALL_EVENTS}?userId=${userData?.id ? userData.id : 0}`);
        return response.data;
    }
	
    static async getEventMainPageFilter(obj) {
		const userData = Utils.getUserData();
        return await  await axios.post(`${LINKS.MAIN_ALL_EVENTS_FILTER}?userId=${userData?.id ? userData.id : 0}`, { params: obj });
    }

    // svg Arena
	static async getCurrentEvent(id) {
        return await axios.get(LINKS.EVENT_INFO.replace("@id@", id));
    }

	static async favoriteToggle(id) {
		const userData = Utils.getUserData();
        return  await axios.post(`${LINKS.SUB_EVENT_FAVORITE_TOGGLE}?userId=${userData?.id ? userData.id : 0}&subEventId=${id}`);
    }
	
	static async getArenaData(id) {
		const response = await axios.post(LINKS.SELL_INFO_PAGE, { userId: 0, login: 'string', fio: 'string', subEventId: id });
        return   response.data;
    }

	static async getImageForEvent(id, size = 'normal') {
		const response = await axios.get(LINKS.FILE_GET_IMAGE_URL.replace("@id@", id).replace("@size@", size))
            .then(res=> {
                const url = res?.request?.responseURL;
                return url ? url : noImg
            })
            .catch(err => {
                console.log({err});
                return noImg
            });
        return response
    }
}

export default HooksApi