import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import InputForm from '../Inputs/InputForm';
import FillBtnForm from '../Buttons/FillBtnForm';
import { TitleH4 } from '../../modules/css/CommonStyles';
import { COLORS, FONTS, MEDIA_QUERY } from '../../config';

const SelectText = styled.span`
    color: ${COLORS.red};
    font-family: ${FONTS.regular};
`;

const Container = styled.div`
    width:100%;
    h4{
        margin: 10px 5px 10px 0;
    }

    .helper {
        display:flex;
        align-items:center;
    }
    .line {
        margin: 25px 0;
        &.button {
            display: flex;
            margin: 25px 0;
            justify-content: center;
            @media screen and ${MEDIA_QUERY.laptop} {
                width:34%;
            }
        }
    }

    .placement{
        width:100%;
        display:flex;
        flex-direction: column;
        & .inputDate {
            flex-direction: row;
            display: flex;
            gap: 12px;
            .inputBox{
                width: 100%;
            }
        }
        @media screen and ${MEDIA_QUERY.laptop} {
            flex-direction: row;
            .category {
                flex: 0.4;
            }
            .date{
                flex: 0.6;
            }
        }
    }

    .personal{
        display:flex;
        flex-wrap: wrap;
        flex-direction:column;
        align-content: flex-start;
        h4{
            flex-basis:100%;
        }
        .inputBox{
            width:100%;
        }
        @media screen and ${MEDIA_QUERY.laptop} {
            flex-direction: row;
            .inputBox{
                flex:1;
            }
        }
    }
`;
export default function FormSellTicket(props) {
    const hookForm = useForm();
    const { handleSubmit } = hookForm;

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="line">
                    <TitleH4>Название </TitleH4>
                    <InputForm placeholder={'БУМАЖНЫЙ БИЛЕТ'} name={'ticketName'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line placement">
                    <div className="placementCol category">
                        <TitleH4>Категория</TitleH4>
                        <InputForm placeholder={'Концерт'} name={'category'} isRequired={true} {...{hookForm}} />
                    </div>
                    <div className="placementCol date">
                        <TitleH4>Дата</TitleH4>
                        <div className="inputDate">
                            <InputForm placeholder={'День'} name={'day'} isRequired={false} {...{hookForm}} />
                            <InputForm placeholder={'Месяц'} name={'month'} isRequired={false} {...{hookForm}} />
                            <InputForm placeholder={'Год'} name={'year'} isRequired={false} {...{hookForm}} />
                        </div>
                    </div>
                </div>
                <div className="line personal">
                    <TitleH4>Персональные данные</TitleH4>
                    <InputForm placeholder={'Имя'} name={'firstName'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'Номер телеофна'} name={'Number'} isRequired={false} {...{hookForm}} />
                    <InputForm placeholder={'email'} name={'Email'} isRequired={false} {...{hookForm}} />
                </div>
                <div className="line button">
                    <FillBtnForm type="submit" title="Добавить мероприятие" />
                </div>

            </form>
        </Container>
    );
}
