import React from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from "effector-react";
import styled from 'styled-components';
import { sendSmsToUser, $isLoadingAuth, resetAuthData } from '../../../modules/effector/auth';
import { modalOpen } from "../../../modules/effector/modal";
import ModalTemplateHeader from '../ModalTemplateHeader';
import ModalTemplateFooter from '../ModalTemplateFooter';
import { MEDIA_QUERY, COLORS } from '../../../config';
import FillBtnForm from '../../Buttons/FillBtnForm';
import NoFillBtn from '../../Buttons/NoFillBtn';
import InputForm from '../../Inputs/InputForm';

const WrapperSignInByPhoneView = styled.form`
	width: 100%;
	margin: 0 auto;

	@media screen and ${MEDIA_QUERY.mobileL} {
		width: 344px;
	}

	.input-field {
		margin: 0;
	}
	.more-btn{
		width: 100%;
		margin-bottom: 122px;
		&:last-child {
		margin-bottom: 24px; 
		}
	}
	#phoneInAuthModalSignInId {
		margin-bottom: 20px !important;
	}
	input {
		box-sizing: border-box!important;
		position: relative;
		display: flex !important;
		padding: 14px 15px 16px !important;
		border-radius: 12px!important;
		border: 1.5px solid ${COLORS.black} !important;
		background-color: ${COLORS.white};
		margin: 0!important;
		box-shadow: none!important;
		height: auto !important;
		font-size: 18px !important;  
		line-height: 22px;
		letter-spacing: 0.02em;
		@media screen and ${MEDIA_QUERY.laptop} {
			max-width: 890px;
			margin: 0 auto!important;
			width: 100% !important;
		}
	}
	.isValid {
		border-color: ${COLORS.red} !important;
	}
`;

export default function SignInByPhoneView(props) {
	const hookForm = useForm({ mode: 'all' });
	const { register, formState: { errors, isValid, }, handleSubmit, reset } = hookForm;
	//TODO effector form
	const isLoadingAuth = useStore($isLoadingAuth);

	const sendAuthPhone = (data) => {
		sendSmsToUser(data.phoneInAuthModalSignInId);
	};

	const onEmailAuth = ()=>{
		modalOpen({modalName:'AuthModal', modalType:'loginView'})
		resetAuthData();
	};

	return (
		<>
		<ModalTemplateHeader title="Вход/Регистрация" />
		<WrapperSignInByPhoneView onSubmit={handleSubmit(sendAuthPhone)}>
			<InputForm
				placeholder={'Номер телефона'}
				name={'phoneInAuthModalSignInId'}
				{...{hookForm}}
				registerData={{
					required: 'Поле обязательно для заполнения!',
						pattern: {
							value: /^8[0-9]{10}$/,
							message: 'Введите корректный номер!',
						},
					maxLength: {
							value: 11,
							message: 'Максимум 11 символов!',
						},
				}}
			/>
			<FillBtnForm title="Получить код" isValid={!isValid} disabled={!isValid} isLoading={isLoadingAuth} />
			<NoFillBtn textValue="ВОЙТИ ПО ПОЧТЕ" callback={onEmailAuth} id="loginView" />
		</WrapperSignInByPhoneView>
		<ModalTemplateFooter />
		</>
	);
}
