import { createEffect, createEvent, createStore, sample } from 'effector';
import HooksApi from '../../API/hooksApi';

export const getCurrentEvent = createEvent(); // params: id
export const setBuyState = createEvent();
export const backStep = createEvent();

export const $currentEvent = createStore(null);
// $currentEvent.watch(state => console.log('$currentEvent', state))
// $arenaData.watch(state => console.log('$arenaData', state))

export const getCurrentEventFx = createEffect(async (id) => {
    const response = await HooksApi.getCurrentEvent(id);
    return response.data;
})

sample({
    clock: getCurrentEvent,
    fn:(data=>data),
    target: getCurrentEventFx
})


sample({
    clock: getCurrentEventFx.doneData,
    fn: (data)=> (data),
    target: $currentEvent
})


export const $arenaData = createStore({});

sample({
    clock: getCurrentEventFx.doneData,
    fn: (data)=> {
        let zoneList = {};
        let sortByArenas = {}
        let sectionsName = {}
        data.arenaZoneBySubEventList.forEach(item=>{
            if(item.name) sectionsName[item.id] = item;
        })

        data.arenaZoneSectorBySubEventList.forEach((item) => {
            if(item.name){
                zoneList[item.id] = item;
                if(typeof sortByArenas[item.arena_zone_id] == "object") {
                    const tempArr = sortByArenas[item.arena_zone_id];
                    tempArr.push(item);
                    sortByArenas[item.arena_zone_id] = tempArr;
                } else {
                    sortByArenas[item.arena_zone_id] = [item];
                }
            }
        })

        // Sort data from backend??
        // Object.entries(sortByArenas).map((item)=>{
        //     const id = item[0];
        //     const data = item[1];
        //     const sortedData = data.sort((a, b) => {
        //         if (a.id < b.id) {
        //             return -1;
        //         }
        //         if (a.id > b.id) {
        //             return 1;
        //         }
        //         return 0;
        //     });
        //     sortByArenas[id] = sortedData;
        // });
        return { sectionsName, sortByArenas, zoneList };
    },
    target: $arenaData
})

export const $buyState = createStore({sector: false, row: false, place: false})
    .on(setBuyState, (state, payload) => ({...state, ...payload }))
    .on(backStep, (state) => {
        const { sector, row, place } = state;
        if(place) {
            return {...state, place: false}
        } else if(row) {
            return {...state, row: false}
        } else if(sector) {
            return {...state, sector: false}
        } 
    });