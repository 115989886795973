import React from 'react';
import styled from 'styled-components';

const Btn = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  border-radius: 32px;
  min-width: 220px;
  padding: 15px;
  color: ${(props) => props.color? props.color : '#19214E'};
  border: 1px solid ${(props) => props.color? props.color : '#19214E'};
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
`;

export default function NoFillBtn(props) {
  const { textValue, callback, id, color } = props;

  return (
    <Btn
      onClick={callback}
      className="more-btn"
      id={id}
      color={color}
    >
      {textValue}
    </Btn>
  );
}
