import React from "react";

const MailInCircle = () => {
    return (
        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="29.8826" cy="30.5" rx="29.8826" ry="30.5" fill="#4C8F23"/>
            <path d="M40.9716 42H18.7784C17.611 41.9986 16.4917 41.5361 15.6662 40.714C14.8407 39.8918 14.3764 38.7771 14.375 37.6144V24.3856C14.3764 23.2229 14.8407 22.1082 15.6662 21.286C16.4917 20.4639 17.611 20.0014 18.7784 20H40.9716C42.139 20.0014 43.2583 20.4639 44.0838 21.286C44.9093 22.1082 45.3736 23.2229 45.375 24.3856V37.6144C45.3736 38.7771 44.9093 39.8918 44.0838 40.714C43.2583 41.5361 42.139 41.9986 40.9716 42V42ZM18.7784 21.5478C18.0227 21.5478 17.298 21.8468 16.7637 22.379C16.2293 22.9112 15.9291 23.6329 15.9291 24.3856V37.6144C15.9291 38.3671 16.2293 39.0888 16.7637 39.621C17.298 40.1532 18.0227 40.4522 18.7784 40.4522H40.9716C41.7273 40.4522 42.452 40.1532 42.9863 39.621C43.5207 39.0888 43.8209 38.3671 43.8209 37.6144V24.3856C43.8209 23.6329 43.5207 22.9112 42.9863 22.379C42.452 21.8468 41.7273 21.5478 40.9716 21.5478H18.7784Z" fill="white" stroke="white"/>
            <path d="M29.8737 34.137C28.9151 34.1385 27.9821 33.8287 27.2162 33.2547L15.1197 24.1431C15.0343 24.0833 14.9618 24.0071 14.9066 23.9188C14.8514 23.8305 14.8146 23.7321 14.7984 23.6293C14.7823 23.5266 14.7871 23.4217 14.8125 23.3208C14.838 23.22 14.8836 23.1253 14.9467 23.0424C15.0097 22.9595 15.0889 22.8901 15.1794 22.8383C15.27 22.7866 15.3701 22.7535 15.4738 22.7412C15.5775 22.7288 15.6826 22.7374 15.7828 22.7664C15.8831 22.7954 15.9765 22.8442 16.0574 22.91L28.1538 32.0319C28.6506 32.4012 29.2539 32.6008 29.8737 32.6008C30.4936 32.6008 31.0969 32.4012 31.5937 32.0319L43.6901 22.91C43.771 22.8442 43.8644 22.7954 43.9646 22.7664C44.0649 22.7374 44.17 22.7288 44.2737 22.7412C44.3774 22.7535 44.4775 22.7866 44.5681 22.8383C44.6586 22.8901 44.7378 22.9595 44.8008 23.0424C44.8639 23.1253 44.9095 23.22 44.935 23.3208C44.9604 23.4217 44.9652 23.5266 44.9491 23.6293C44.9329 23.7321 44.8961 23.8305 44.8409 23.9188C44.7857 24.0071 44.7132 24.0833 44.6278 24.1431L32.5313 33.2547C31.7653 33.8287 30.8324 34.1385 29.8737 34.137V34.137Z" fill="white" stroke="white"/>
            <path d="M15.4579 39.8433C15.3003 39.8436 15.1463 39.7961 15.0165 39.7071C14.8866 39.6182 14.787 39.492 14.7308 39.3454C14.6746 39.1987 14.6645 39.0385 14.7018 38.886C14.7392 38.7335 14.8222 38.596 14.9399 38.4915L24.4719 30.0248C24.6258 29.888 24.828 29.8177 25.0339 29.8293C25.2399 29.8409 25.4328 29.9335 25.5702 30.0868C25.7076 30.24 25.7782 30.4414 25.7666 30.6465C25.7549 30.8516 25.6619 31.0437 25.508 31.1806L15.976 39.6473C15.8344 39.7755 15.6493 39.8456 15.4579 39.8433V39.8433ZM44.2925 39.8433C44.1011 39.8456 43.916 39.7755 43.7744 39.6473L34.2423 31.1806C34.1661 31.1128 34.1041 31.0308 34.0597 30.9391C34.0154 30.8475 33.9896 30.7481 33.9838 30.6465C33.978 30.5449 33.9924 30.4432 34.0261 30.3472C34.0598 30.2511 34.1121 30.1626 34.1802 30.0868C34.2482 30.0109 34.3306 29.9491 34.4226 29.9049C34.5146 29.8607 34.6144 29.835 34.7164 29.8293C34.8184 29.8235 34.9205 29.8378 35.017 29.8714C35.1134 29.9049 35.2022 29.9571 35.2784 30.0248L44.8053 38.4915C44.923 38.596 45.006 38.7335 45.0434 38.886C45.0807 39.0385 45.0706 39.1987 45.0144 39.3454C44.9582 39.492 44.8586 39.6182 44.7287 39.7071C44.5988 39.7961 44.4449 39.8436 44.2873 39.8433H44.2925Z" fill="white" stroke="white"/>
        </svg>
    );
}

export default MailInCircle;