import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useStore } from 'effector-react';
import parse from "html-react-parser";
import styled from 'styled-components';
// import { $currentEvent, $buyState, $arenaData, setBuyState, backStep } from '../../../modules/effector/currentEvent';
import { $currentEvent, setBuyState } from '../../../modules/effector/currentEvent';

const SvgContainer = styled.svg`
  width: 1520px;
  height: 560px;
  cursor: pointer;

  .svg-element {
    fill-opacity: .4;
    transition: fill-opacity 0.5s;
  }

  .svg-element:hover {
    fill-opacity: 1;
  }

  & > * {
    transform: translateX(30%);
    margin: 0 auto;
  }
`;

export default function ArenaScheme(props) {
  const { onOpenList } = props;
  const currentEvent = useStore($currentEvent);

  const onElementSVG = (item)=>{
    onOpenList();
    setBuyState({sector: item.arena_zone_id, row: item.id});
  };

  const renderSvgArena = (data)=>{
    const jsxHtmlArray = data.map((item, index)=>{
      let jsxElement = parse((item.svg), {
        replace: domNode => {
            // remove class svg-element element without name (stroke)
            if(domNode.attribs?.class?.includes('svg-element') && !Boolean(item.name)) {
              domNode.attribs.class = domNode.attribs.class.replace('svg-element','');
            }
            return domNode;
        }
      })
      // refactor find how add key in jsxElement
      return (<g className={`${item.arena_zone_id}-zone ${item.id}-row`} key={`${index}${item.name? item.name : 'nonename'}`} onClick={()=>{ onElementSVG(item)}}>{jsxElement}</g>)
    })

    return (<>
      {jsxHtmlArray}
    </>)
  }

  return (
    <TransformWrapper centerZoomedOut centerOnInit>
        <TransformComponent contentClass='zoomContent' wrapperClass='zoomWrapper'>
          {currentEvent &&
            <SvgContainer>
              {renderSvgArena(currentEvent.arenaZoneSectorBySubEventList)}
            </SvgContainer>
          }
        </TransformComponent>
      </TransformWrapper>
  );
}
