/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    h4 {
      font-weight: bold;
      font-size: 16px;
      line-height: 32px;
      color: #151820;
      margin: 0;
    }
    
    .radio-group {
      margin: 0;
      
      input + label {
        position: relative;
        padding-left: 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #151820;
        
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 18px;
          top: calc(50% - 9px);
          height: 18px;
          border-radius: 50%;
          border: 1px solid #151820;
        }
      }

      input:checked + label {
        &::before {
          border: 5px solid #D31F26;
        }
      }
    }
  `;

export default function Radio() {
  return (
    <Container className="radio-container">
      <h4>Город</h4>
      <div>
        <p className="radio-group">
          <input id="1" name="city" type="radio" />
          <label htmlFor="1">Москва</label>
        </p>
        <p className="radio-group">
          <input id="2" name="city" type="radio" />
          <label htmlFor="2">Москва</label>
        </p>
        <p className="radio-group">
          <input id="3" name="city" type="radio" />
          <label htmlFor="3">Москва</label>
        </p>
      </div>
    </Container>
  );
}
