import React, { useEffect, useState } from 'react';
import { useStore } from "effector-react"
import { $shuffleEventList, $subEventList} from '../../modules/effector/events';
import { MEDIA_QUERY, FONTS, COLORS } from '../../config';
import { TitleH2 } from '../../modules/css/CommonStyles';
import FillBtn from '../../components/Buttons/FillBtn';
import CardEvent from '../Cards/CardEvent/CardEvent';
import Utils from '../../services/utils/Utils';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-bottom: 20px;
	justify-content: center;

	.isEmptyText{
		font-family: ${FONTS.bold};
		font-weight: 700;
		font-size:46px;
		line-height:58px;
		color: ${COLORS.semiGray};
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		gap: 0;
		column-gap: calc(2% / 2);
		row-gap: 50px;
	}
`;

const BoxIsSearchEmpty = styled.div`
	display: flex;
	max-width: 400px;
	text-align: center;
    align-items: center;
    flex-direction: column;
    margin: 100px auto 200px;
	p{
		font-family: ${FONTS.bold};
		font-weight: 700;
		font-size:46px;
		line-height:58px;
		color: ${COLORS.semiGray};
	}
`;

const BtnContainer = styled.div`
	margin: 0 auto;
	@media screen and ${MEDIA_QUERY.laptop} {
		width:242px;
	}
`;

// TODO REWORK board for all type request

export default function EventBoard(props) {
	const [events, setEvents] = useState([]);
	const [allEvents, setAllEvents] = useState([]);
	const [eventCount, setEventCount] = useState(4);
	const shuffleEventList = useStore($shuffleEventList);
	const subEventList = useStore($subEventList);
	const { title, searchInput, isPathName, typeBoard } = props;
	const { switcherEvents } = Utils;

	useEffect(()=>{
		setEvents(allEvents);
	},[allEvents]);

	useEffect(()=>{
		const inputLowerCase = searchInput? searchInput.toLowerCase() : '';
		const filteredEvents = allEvents.filter((item)=>(
			item.name.toLowerCase().includes(inputLowerCase) ||
			item.name_en.toLowerCase().includes(inputLowerCase) ||
			item.name_translit.toLowerCase().includes(inputLowerCase)
		));
		setEvents(filteredEvents);
	},[searchInput]);

	useEffect(() => {
		switcherEvents(typeBoard, subEventList, shuffleEventList, setAllEvents);
	},[typeBoard, subEventList, shuffleEventList])

	useEffect(() => {
		if (window.innerWidth > 1140) {
			setEventCount(6);
		}
	}, [window.onresize]);

	const getMoreEvents = (evt) => {
		evt.preventDefault();
		setEventCount(eventCount + (window.innerWidth > 1140 ? 6 : 4));
	};

	const addNewEvent = () => {
		window.location.href = '/add-event';
	};

	return (
		<>
			{(searchInput ? searchInput : title) && <TitleH2>{searchInput
				? `Результаты «${searchInput}»`
				: title}
			</TitleH2>}
			<Container>
				{ events.length > 0 && events.slice(0, eventCount).map((event) => (
					<CardEvent key={event.event_id} event={event} href={`${isPathName ? location.pathname : ''}/event/${event.event_id}`} />
				))}
				{events.length == 0 && <p className='isEmptyText'>Мероприятий не найдено</p>}
			</Container>
			{ (searchInput && events.length == 0) && <BoxIsSearchEmpty>
				<p>По запросу «{searchInput}» <br />мероприятий не найдено</p>
				<FillBtn title="ДОБАВИТЬ МЕРОПРИЯТИЕ" callback={addNewEvent} isActive />
			</BoxIsSearchEmpty>}
			{ (events && eventCount < events.length) && 
				<BtnContainer>
					<FillBtn className='btn-more' title="ПОКАЗАТЬ ЕЩЕ" callback={getMoreEvents} marginAuto isActive />
				</BtnContainer>
			}
		</>
	);
}
