import React, { useState, useEffect } from 'react';
import { TitleH2, TitleH3, SubText2 } from '../../modules/css/CommonStyles';
import CardInfoIcon from '../../components/Cards/CardInfo/CardInfo';
import FormAddEvent from '../../components/Forms/FormAddEvent';
import { useParams } from 'react-router-dom';
import { MEDIA_QUERY } from '../../config';
import styled from 'styled-components';

const Container = styled.div`
    display:flex;
    padding:20px;
    margin: 0 auto;
    max-width:1500px;
    align-items: center;
    flex-direction: column-reverse;
    @media screen and ${MEDIA_QUERY.laptop} {
		flex-direction: row;
	}
`;

const ContainerForm = styled.div`
    width:100%;
    @media screen and ${MEDIA_QUERY.laptop} {
		width:66%;
        padding-right:100px;
	}
`;

const ContainerSideContent = styled.div`
    width:100%;
    @media screen and ${MEDIA_QUERY.laptop} {
		width:34%;
	}
`;

const CardEventInfo = styled.div`
    img {
        border-radius:24px;
        width:100%;
    }
`;

export default function AddEvent() {

	return (
		<Container>
            <ContainerForm>
                <TitleH2>Добавить мероприятие</TitleH2>
                <FormAddEvent />
            </ContainerForm>
            <ContainerSideContent>
                <CardInfoIcon type={'MailInCircle'} />
            </ContainerSideContent>
		</Container>
	);
}
