import React from 'react';
import BtnClose from '../Buttons/BtnClose';
import styled from 'styled-components';
import { COLORS } from '../../config';
import { modalClose } from '../../modules/effector/modal';

const TitleBlock = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center;
`;

const Title = styled.p`
	margin: 0;
	font-size: 32px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	color: ${COLORS.black};
	font-family: 'Gilroy-Bold', sans-serif;
`;

const ModalCloseBtn = styled.div`
	display: flex;
	position: absolute;
	top: 4px;
	right: -21px;
	a {
		display: flex;
		svg {
			width: 20px
		}
	}
`;

export default function ModalTemplateHeader(props) {
	const { title } = props;

	const onClose = ()=>{modalClose()};

	return (
		<TitleBlock>
			{title && <Title>{title}</Title>}
			<ModalCloseBtn onClick={onClose}>
				<BtnClose />
			</ModalCloseBtn>
		</TitleBlock>
	);
}
