import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    ${(props)=>props.customStyle? props.customStyle: ''}
`;

export const TextHigher = styled.p`
    font-family: 'Gilroy-medium', sans-serif;
    font-weight: 500;
    line-height: 36px;
    font-size:18px;
`;

export const BoldText = styled.span`
    font-family: 'Gilroy-medium', sans-serif;
    font-weight: 700;
    line-height: 36px;
    font-size:18px;
`;

export const ContainerCollapse = styled.div``