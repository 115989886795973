import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS } from '../../../config';

const Category = styled.a`
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	display: block;
	padding: 12px;
	width: 100%;
	height: 100%;
	border-radius: 12px;
	color:${COLORS.white};
	
	& .type {
		font-weight: 600;
		font-size: 16px;
		line-height: 18px;
	}

	& .count {
		font-size: 12px;
	}
	
	& .ticket-container {
		position: absolute;
		right: -23px;
		bottom: -15px;
		width: 92px;
		height: 65px;
	}
	
	& .ticket-decor {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		& .type {
			font-weight: 600;
			font-size: 24px;
			line-height: 100%;
		}

		& .count {
			font-size: 24px;
			line-height: 100%;
		}

		& .ticket-container {
			width: 220px;
			height: 124px;
			right: -67px;
			bottom: -20px;
		}
		
	}
`;

export default function CategoryCard(props) {
	const { categoryInfo: { color, category, count, img, href } } = props;

	return (
		<Category style={{ backgroundColor: color }} href={`/${href}`}>
			<span className="type">{category} • </span>
			{' '}
			{count > 0 && <span className="count">{count}</span>}
			<div className="ticket-container">
				<img className="ticket-decor" src={img.path} alt={img.alt} />
			</div>
		</Category>
	);
}
