import React from 'react';
import styled from 'styled-components';
import { useStore } from "effector-react";
import { useForm } from 'react-hook-form';
import { $authData, $isLoadingAuth, confirmSmsUser } from '../../../modules/effector/auth';
import FillBtnForm from '../../../components/Buttons/FillBtnForm';
import NoFillBtn from '../../../components/Buttons/NoFillBtn';
import { modalOpen } from '../../../modules/effector/modal';
import ModalTemplateHeader from '../ModalTemplateHeader';
import ModalTemplateFooter from '../ModalTemplateFooter';
import { COLORS, MEDIA_QUERY } from '../../../config'
import InputForm from '../../Inputs/InputForm';

const WrapperTitleConfirm = styled.div`
	margin-bottom: 16px;
	div {
		margin-bottom: 0;
	}
`;

const WrapperConfirmView = styled.form`
	width: 100%;
	margin: 0 auto;

	@media screen and ${MEDIA_QUERY.mobileL} {
		width: 344px;
	}

	.input-field {
		margin: 0;
	}
	.more-btn{
		width: 100%;
		margin-bottom: 44px;
		&:last-child {
		margin-bottom: 24px; 
		}
	}
	#phoneCodeId {
		margin-bottom: 20px !important;
	}
`;

const ViewText = styled.p`
    font-family: 'Gilroy-medium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 36px;
    line-height: 22px;
    span {
		text-decoration: underline;
		color: ${COLORS.red};  
		display: flex;
		margin: 0 auto;
		cursor: pointer;
		display: block;
    }
`;
export default function CodeConfirmView() {
	const authData = useStore($authData);
	const isLoadingAuth = useStore($isLoadingAuth);
	const hookForm = useForm({ mode: 'all' });
	const { register, formState: { errors, isValid, }, handleSubmit, reset } = hookForm;

	const onPhoneAuth = ()=>{
		modalOpen({modalName:'AuthModal', modalType:'phoneView'});
	};

	const sendConfirmCode = (data) => {
		confirmSmsUser(data.phoneCodeId);
	};

	return (
		<>
			<WrapperTitleConfirm>
				<ModalTemplateHeader title="Код подтверждения" />
			</WrapperTitleConfirm>

			<ViewText>
				На номер {' '} {authData.phone} {' '} отправили
				<br />
				смс с кодом  подтверждения.
				<span onClick={onPhoneAuth} onKeyDown={onPhoneAuth} role="button" tabIndex="0">Ввести другой номер</span>
			</ViewText>
			<WrapperConfirmView onSubmit={handleSubmit(sendConfirmCode)}>
				{/* <BaseInput placeholder="Код подтверждения" icon="none" id="phoneCodeId" /> */}
				<InputForm placeholder={'Код подтверждения'} name={'phoneCodeId'} isRequired={true} {...{hookForm}} />
				<FillBtnForm isActive title="ВОЙТИ" callback={sendConfirmCode} isLoading={isLoadingAuth} />
				<NoFillBtn textValue="ОТПРАВИТЬ НОВЫЙ КОД" callback={sendConfirmCode} id="byPhone" />
			</WrapperConfirmView>
			<ModalTemplateFooter />
		</>
	);
}
