import React, { useState } from 'react';
import { MEDIA_QUERY, COLORS, FONTS, TICKET_TYPE } from '../../config';
import CheckBox from '../../assets/svg/CheckBox';
import FillBtnForm from '../Buttons/FillBtnForm';
import ButtonForm from '../Buttons/ButtonForm';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';


const RadioButton = styled.div`
    width: 100%;
    padding-right:10px;
    input[type="radio"]{
        display:none;
    }
`;


export default function RadioButtons(props) {
    // console.log({type:props.type, getValue:  getValues('typeTicket')});
    const { register, setValue } = props.hookForm;

    return (
        <>
            {TICKET_TYPE.map((item)=>{
                const onClick = ()=>{
                    setValue(props.name, item.type);
                    props.setType(item.type);
                };
                return(
                    <RadioButton onClick={onClick} key={`radio${item.type}`}>
                        <ButtonForm type="button" title={item.title} isFill={item.type == props.currentType} isNotRound />
                        {/* <FillBtnForm type="button" title={item.title} isValid={item.type == props.currentType} isNotRound /> */}
                        <input type="radio" name={props.name} value={item.type} {...register(props.name, { required: false })} />
                    </RadioButton>
                )
            })}
        </>
    )
}
