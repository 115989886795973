import React from "react";

const CardInCircle = () => {
    return (
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5076 61C47.0113 61 60.3902 47.3447 60.3902 30.5C60.3902 13.6553 47.0113 0 30.5076 0C14.0039 0 0.625 13.6553 0.625 30.5C0.625 47.3447 14.0039 61 30.5076 61Z" fill="#29419C"/>
            <path d="M40.1462 20H20.8538C19.567 20.0015 18.3333 20.5055 17.4234 21.4015C16.5134 22.2975 16.0015 23.5123 16 24.7794V37.2206C16.0015 38.4877 16.5134 39.7025 17.4234 40.5985C18.3333 41.4945 19.567 41.9985 20.8538 42H40.1462C41.433 41.9985 42.6667 41.4945 43.5766 40.5985C44.4866 39.7025 44.9985 38.4877 45 37.2206V24.7794C44.9985 23.5123 44.4866 22.2975 43.5766 21.4015C42.6667 20.5055 41.433 20.0015 40.1462 20ZM20.8538 21.9117H40.1462C40.9183 21.9126 41.6586 22.2149 42.2046 22.7525C42.7505 23.2902 43.0576 24.0191 43.0585 24.7794V26.0331H17.9415V24.7794C17.9424 24.0191 18.2495 23.2902 18.7954 22.7525C19.3414 22.2149 20.0817 21.9126 20.8538 21.9117ZM40.1462 40.0883H20.8538C20.0817 40.0874 19.3414 39.7851 18.7954 39.2475C18.2495 38.7098 17.9424 37.9809 17.9415 37.2206V27.9448H43.0585V37.2206C43.0576 37.9809 42.7505 38.7098 42.2046 39.2475C41.6586 39.7851 40.9183 40.0874 40.1462 40.0883ZM40.405 35.6178C40.405 35.8713 40.3027 36.1145 40.1206 36.2937C39.9386 36.473 39.6917 36.5737 39.4342 36.5737H37.1067C36.8492 36.5737 36.6023 36.473 36.4202 36.2937C36.2382 36.1145 36.1359 35.8713 36.1359 35.6178C36.1359 35.3643 36.2382 35.1212 36.4202 34.9419C36.6023 34.7627 36.8492 34.662 37.1067 34.662H39.4342C39.6917 34.662 39.9386 34.7627 40.1206 34.9419C40.3027 35.1212 40.405 35.3643 40.405 35.6178Z" fill="white" stroke="white"/>
        </svg>
    );
}

export default CardInCircle;