import React from 'react';
import { MEDIA_QUERY, COLORS, FONTS } from '../../config';
import styled from 'styled-components';

const InputBox = styled.div`
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    .notDisplay{
        opacity:0;
    }
    span {
        margin-bottom: 8px;
        color:${COLORS.red};
        font-family: ${FONTS.regular};
        font-size:16px;
        line-height: 20px;
    }
    input{
        box-shadow: inset 0px 0px 1px 1px ${COLORS.black} !important;
        border-radius: 12px !important;
        padding: 0 7px !important;
        width: 100% !important;
        box-sizing: border-box;
    }
    @media screen and ${MEDIA_QUERY.tablet} {
		padding-right:15px;
	}
`;


export default function InputForm(props) {
    const { register, formState: { errors } } = props.hookForm;
    const { placeholder, name, isRequired, className, registerData } = props;

	return (
		<InputBox className={`inputBox${className? ` ${className}` : ''}`}>
            <input placeholder={placeholder} {...register(name, registerData ? registerData : { required: isRequired })} />
            <span className={errors[name] ? '' : 'notDisplay'}>{registerData ? registerData.required : 'Обязательное поле'}</span>
        </InputBox>
	);
}
