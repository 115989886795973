import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AboutCompany from '../../pages/AboutCompany/AboutCompany';
import LicensingAgreement from '../../pages/AboutCompany/subPageAbout/LicensingAgreement';
import AgencyContract from '../../pages/AboutCompany/subPageAbout/AgencyContract';
import TicketMarket from '../../pages/AboutCompany/subPageAbout/TicketMarket';
import Requisites from '../../pages/AboutCompany/subPageAbout/Requisites';
import Guarantee from '../../pages/AboutCompany/subPageAbout/Guarantee';
import Service from '../../pages/AboutCompany/subPageAbout/Service';
import Help from '../../pages/AboutCompany/subPageAbout/Help';

import ProfileUser from '../../pages/ProfileUser/ProfileUser';
import PaymentВetails from '../../pages/ProfileUser/subPages/PaymentВetails';
import PersonalData from '../../pages/ProfileUser/subPages/PersonalData';
import MyPayments from '../../pages/ProfileUser/subPages/MyPayments';
import MyOffers from '../../pages/ProfileUser/subPages/MyOffers';
import MyOrders from '../../pages/ProfileUser/subPages/MyOrders';

import SellTicketEvent from '../../pages/SellTicketEvent/SellTicketEvent';
import SellTickets from '../../pages/SellTickets/SellTickets';
import Favourites from '../../pages/Favourites/Favourites';
import AddEvent from '../../pages/AddEvent/AddEvent';
import MainPage from '../../pages/MainPage/MainPage';
import EventPage from '../../pages/Event/EventPage';

import LoadingLayout from '../../components/Layout/Layout';
import Arena from '../../components/Arena/Arena';

export default function Navigation() {
	return (
        <Routes>
            <Route path="/" element={<LoadingLayout />}>
                <Route index path="/" element={<MainPage />} />
                <Route path="/sell/:id" element={<Arena />} /> {/* TODO check component */}
                <Route path="/event/:id" element={<EventPage />} />
                <Route path="/favourites" element={<Favourites />} />
                <Route path="/concerts" element={<MainPage />} />
                <Route path="/hockey" element={<MainPage />} />
                <Route path="/theatre" element={<MainPage />} />
                <Route path="/festival" element={<MainPage />} />
                <Route path="/football" element={<MainPage />} />
                <Route path="/kids" element={<MainPage />} />
                <Route path="/sports" element={<MainPage />} />
                <Route path="/selltickets" element={<SellTickets />} />
                <Route path="/selltickets/event/:id" element={<SellTicketEvent />} />
                <Route path="/add-event" element={<AddEvent />} />
                
                <Route path="/about" element={<AboutCompany />}> 
                    <Route path="service" element={<Service />} />
                    <Route path="guarantee" element={<Guarantee />} />
                    <Route path="requisites" element={<Requisites />} />
                    <Route path="ticket-market" element={<TicketMarket />} />
                    <Route path="help" element={<Help />} />
                    <Route path="licensing-agreement" element={<LicensingAgreement />} />
                    <Route path="agency-contract" element={<AgencyContract />} />
                </Route>

                <Route path="/profile" element={<ProfileUser />}>
                    <Route path="personal" element={<PersonalData />} />
                    <Route path="payment-betails" element={<PaymentВetails />} />
                    <Route path="orders" element={<MyOrders />} />
                    <Route path="offers" element={<MyOffers />} />
                    <Route path="payments" element={<MyPayments />} />
                </Route>
            </Route>
        </Routes>
	);
}
