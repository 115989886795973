import React from 'react';
import { Container, TextHigher, BoldText } from './styles';
import { TitleH2, TitleH3, Text } from '../../../modules/css/CommonStyles';

export default function TicketMarket() {
    return (
        <Container>
            <TitleH2>Биржа билетов</TitleH2>
            <Text>
                Биржа билетов от компании Eticket4 — это инновационный сервис по продаже и покупке билетов на спортивные и развлекательные мероприятия с механизмом безопасной сделки. Приглашаем к сотрудничеству профессиональных брокеров. 
            </Text>
            <TitleH3>Преимущества биржи Eticket4</TitleH3>
            <TextHigher>
                <BoldText>Лояльность.</BoldText> Мы не взимаем комиссию за продажу билетов. <br />
                <BoldText>Скорость.</BoldText> Вы получаете выплаты своевременно и без задержек. <br />
                <BoldText>Безопасность.</BoldText> На нашей бирже работает механизм безопасной сделки. <br />
                <BoldText>Поддержка.</BoldText> Наши консультанты на связи 24/7. <br />
                <BoldText>Гибкость.</BoldText> Мы открыты к вашим предложениям. <br />
                <BoldText>Удобство.</BoldText> Интерфейс биржи ориентирован на комфорт пользователей. <br />
            </TextHigher>
        </Container>
    );
}