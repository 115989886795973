import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../config';

const Btn = styled.button`
    width: 100%;
	padding: 15px;
    display: inline-flex;
    box-sizing: border-box;
	justify-content: center;
	border-radius:${props => props.isNotRound ? `12px` : `32px`};
    border: 1px solid ${props => props.isFill ? `${COLORS.red}` : `${COLORS.black}`};
	background-color: ${props => props.isFill ? `${COLORS.red}` : `${COLORS.white}`};
	color: ${props => props.isFill ? `${COLORS.white}` : `${COLORS.black}`};
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	font-size: 14px;
	text-transform: uppercase;
	${props => props.isNotActive ? `opacity: .5;` : ""};
    &:focus {
        background-color: ${COLORS.red};
    }
`;

export default function ButtonForm(props) {
    const { type, title, isFill, isNotRound, isActive } = props;

	return (
		<Btn { ...{ type, isFill, isNotRound, isActive } }>
			{title}
		</Btn>
	);
}
