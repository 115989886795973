import React from 'react';
import styled from 'styled-components';
import CategorySlider from '../Sliders/CategorySlider';
import { MEDIA_QUERY } from '../../config';
import { TitleH2 } from '../../modules/css/CommonStyles';

const Wrapper = styled.section`
  .carousel-slider {
    height: 105px!important;
  }
  
  .carousel-item {
    width: 50vw!important;
    height: 96px!important;
    min-height: auto!important;
    border-radius: 12px!important;
  }
  
  @media screen and ${MEDIA_QUERY.laptop} {
    
    .carousel-item {
      width: 23%!important;
      height: 186px!important;
      margin-right: 20vw!important;
    }

    .carousel-slider {
      height: 186px!important;
    }
  }
`;

export default function CategorySection() {
  return (
    <Wrapper>
      <TitleH2>Категории мероприятий</TitleH2>
      <CategorySlider />
    </Wrapper>
  );
}
