import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import parse from "html-react-parser";
import { getCurrentEvent, $currentEvent } from '../../modules/effector/currentEvent';
import CategorySection from '../../components/CategorySection/CategorySection';
import CurrentEvent from '../../components/CurrentEvent/CurrentEvent';
import EventBoard from '../../components/EventBoard/EventBoard';
import { TitleH3 } from '../../modules/css/CommonStyles'
import Arena from '../../components/Arena/Arena';
import Utils from '../../services/utils/Utils';
import { FONTS, MEDIA_QUERY, BREAK_POINTS } from "../../config";

const EventPanel = styled.div`
    width: 100%;
	padding: 90px 20px 20px;
	
	@media screen and (max-width: ${BREAK_POINTS.tablet}) {
		height: 100%;
		padding: 0;
	}
`;

const About = styled.div`
    width: 100%;
	padding: 90px 20px 20px;
	.description {
		font-family: ${FONTS.regular};
		font-size: 18px;
	}
`;

export default function EventPage() {
	const currentEvent = useStore($currentEvent);
	const isMobile = Utils.isMobile();
	const isHide = document.body.clientWidth < 721 && isMobile;
	const { id } = useParams();

	useEffect(() => {
		getCurrentEvent(id)
	}, []);

	return (
		<EventPanel>
			{isHide
				? <>
					{currentEvent && <Arena />}
				</>
				: <>
					{currentEvent && <>
					<CurrentEvent event={currentEvent} />
						<Arena />
						<About>
							<TitleH3>О событии</TitleH3>
							<p className='description'>
								{parse(currentEvent.eventDetail.description)}
								{/* throw error validation parse() */}
							</p>
						</About>
					</>}
					<EventBoard />
					<CategorySection />
				</>
			}
		</EventPanel>
	);
}
