import React from 'react';
import { Container } from './styles';
import { TitleH2, Text } from '../../../modules/css/CommonStyles';

export default function Service() {
    return (
        <Container>
            <TitleH2>Сервис Eticket4 </TitleH2>
            <Text>
                «Eticket4» – это первая в России P2P-платформа для участников вторичного рынка билетов на мероприятия с 2016 года. С 2018 сервис является партнером «Сколково». На сегодняшний день платформу посещает около 50000 человек в сутки, около 1500 билетных брокеров аккредитованы платформой и сотрудничают с компанией на постоянной основе.
            </Text>
            <Text>
                В начале 2018 года команда «Eticket4» начала разработку платформы на базе блокчейн-экосистемы Эфириум. С помощью функции «escrow» платформа способна осуществлять условное депонирование, иными словами – удерживать средства покупателя до исполнения условий продавцом. Eticket4 - платформа, позволяющая осуществлять безопасные сделки на вторичном рынке билетов на мероприятия.
            </Text>
            <Text>
                Основные цели развития: масштабировать платформу в Западную Европу, США, Азиатско-Тихоокеанский регион; создать площадку с обучаемым алгоритмом рекомендаций мероприятий и автоматизированным цифровым маркетингом; консолидировать вторичный рынок и обеспечить слияние с первичным.
            </Text>
        </Container>
    );
}