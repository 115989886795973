import React, { useState } from 'react';
import styled from 'styled-components';
import SearchBlock from '../../components/SearchBlock/SearchBlock';
import EventSlider from '../../components/Sliders/EventSlider';
import CategorySection from '../../components/CategorySection/CategorySection';
import Additionally from '../../components/Additionally/Additionally';
import EventBoard from '../../components/EventBoard/EventBoard';
import { MEDIA_QUERY } from '../../config';

const MainPanel = styled.div`
    width: 100%;
`;

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 20px;
	padding-bottom: 10px;
	box-sizing: border-box;
	
	& > a {
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	@media screen and ${MEDIA_QUERY.laptop} {
		margin: 0 auto;
		max-width: 1560px;
		padding: 0 20px 0 20px;
	}
`;

export default function MainPage() {
	const [searchInput, setSearchInput] = useState('');
	const typeBoard = window.location.pathname.substring(1);

	return (
		<MainPanel>
			<SearchBlock
				setInput={setSearchInput}
				title={'Билеты на мероприятия'}
				placeholder={'Поиск билетов на мероприятия'}
			/>
			{!Boolean(searchInput) && <EventSlider {...{ typeBoard }} /> }
			<Wrapper>
				{!Boolean(searchInput) &&  <CategorySection />}
				<EventBoard title={'Самые популярные'} {...{searchInput, typeBoard}} />
				<Additionally />
			</Wrapper>
		</MainPanel>
	);
}
