import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS } from '../../config';

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  @media screen and ${MEDIA_QUERY.laptop} {

    .eventCard--current & {
      display: none;
    }
  }
`;

const Chip = styled.div`
  border-radius: 20px;
  padding: 3px 12px 3px 12px;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: ${COLORS.white};
`;

const ChipType = styled(Chip)`
  
`;

const ChipPrice = styled(Chip)`
  background-color: #151820;
`;

export default function ChipList(props) {
  const style = {
    backgroundColor: props.color,
  };
  return (
    <List>
      <ChipType style={style}>
        {props.categoryName}
      </ChipType>
      <ChipPrice>
        от
        {' '}
        {props.cost}
        {' '}
        ₽
      </ChipPrice>
    </List>
  );
}
