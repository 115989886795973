import React from 'react';
import styled from 'styled-components';
import { FONTS, COLORS, MEDIA_QUERY } from '../../config';

const Btn = styled.a`
	width:100%;
    font-family: ${FONTS.medium};
    // padding: 15px 20px;
    padding: 15px;
    margin: 0px ${(props) => props.marginAuto ? 'auto' : ''};
    text-align: center;
	display: block;
    border-radius: 32px;
    box-sizing: border-box;
    justify-content: center;
    ${(props) => props.isActive
			? `background-color: ${COLORS.red};
            color: ${COLORS.white};`
			: `background-color:${props.isGray ? COLORS.lightGray : COLORS.white};
            color: ${COLORS.black};
            border:1px solid ${props.isGray ? COLORS.lightGray : COLORS.black};`
    }
    ${(props) => props.isGray
        ? ` font-size: 20px;
            font-weight: 500;`
        : ` font-size: 14px;
            font-weight: 600;`};
`;

//TODO replace on ButtonForm and remove component

export default function FillBtn(props) {
    return (
        <Btn
            className={props.className}
            value={props.value}
            onClick={props.callback}
            isActive={props.isActive}
            marginAuto={props.marginAuto}
            isGray={props.isGray}
		>
			{props.title}
        </Btn>
    );
}
