import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { getAllEvents } from './modules/effector/events';
import ModalTemplate from './components/Modal/ModalTemplate';
import Navigation from './modules/navigation/navigation';

// eslint-disable-next-line import/extensions
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/js/materialize.min.js';
import './modules/css/common.css'; // reset materialze

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function App() {
	useEffect(() => {
		getAllEvents();
	}, []);

	return (
		<Router>
			<Navigation />
			<ModalTemplate />
		</Router>
	);
}
