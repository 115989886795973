import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../config';

const TextFooter = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	white-space: pre-line;
	font-family: 'Gilroy-medium';
	color: ${COLORS.black};
	.color {
		color: ${COLORS.red};
	}
`;

export default function ModalTemplateFooter(props) {
	return (
		<TextFooter>
			При входе или регистрации вы  соглашаетесь
			<br /> с {' '}
			<span className="color">Политикой конфиденциальности</span>
			{' '} и {' '}
			<span className="color">Условиями и Положениями</span>
		</TextFooter>
	);
}
