import React from 'react';
import PageMenu from '../../components/PageMenu/PageMenu';
import { Outlet } from "react-router-dom";
import styled from 'styled-components';
import { MEDIA_QUERY } from "../../config";

const Container = styled.div`
    display:flex;
    width: 100%;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    padding: 20px;
    @media screen and ${MEDIA_QUERY.tablet} {
        flex-direction: row;
	}
`;

const Content = styled.div`
    width: 100%;
    @media screen and ${MEDIA_QUERY.tablet} {
        width: 70%;
        padding-left: 40px;
	}
`;

const menu = [
    { url: "service", title:"Сервис Eticket4" },
    { url: "guarantee", title:"Гарантии" },
    { url: "requisites", title:"Реквизиты" },
    { url: "ticket-market", title:"Биржа билетов" },
    { url: "help", title:"Помощь" },
    { url: "licensing-agreement",title:"Лиценз. договор" },
    { url: "agency-contract", title:"Агентский договор" }
];

export default function AboutCompany(props) {

    return (
        <Container>
            <PageMenu arrayMenu={menu} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
}
