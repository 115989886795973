import React, { useEffect, useState } from 'react';
import { Modal } from 'react-materialize';
import styled from 'styled-components';
import Utils from '../../services/utils/Utils';
import { useStore } from "effector-react";
import { $modalState } from '../../modules/effector/modal';
import DateRangeModal from './DateRangeModal/DateRangeModal';
import AuthModal from './AuthModal/AuthModal';


const ModalWrapper = styled(Modal)`
	& {
		width: 512px;
		height: auto;
		border-radius: 20px;
		padding: 28px 45px 22px;
	}  
	& .modal-content {
		padding: 0;
		h4 {
		display: none;
		margin: 0
		}
	}
	& .modal-footer {
		display: none;
	}
`;

const ModalInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export default function ModalTemplate() {
	const { isMobile } = Utils;
	const modalState = useStore($modalState);
	// const formState = useForm({ mode: 'all' }); // TODO FIRST формы на все модальные окна

	return (
		<ModalWrapper
			actions={false}
			bottomSheet={isMobile()}
			fixedFooter={false}
			id="ModalId"
			open={Boolean(modalState.modalName)}
			options={{
				dismissible: false,
				endingTop: '10%',
				inDuration: 250,
				onCloseEnd: null,
				onCloseStart: null,
				onOpenEnd: null,
				onOpenStart: null,
				opacity: 0.5,
				outDuration: 250,
				preventScrolling: false,
				startingTop: '5%',
			}}
		>
			<ModalInnerWrapper>
				{(() => {
					switch (modalState.modalName) {
						case 'AuthModal':
							return <AuthModal />;
						case 'DateRangeModal':
							return <DateRangeModal />;
					}
				})()}
			</ModalInnerWrapper>
		</ModalWrapper>
	);
}
