import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS, FONTS, MEDIA_QUERY } from '../../config';
import NoFillBtn from '../../components/Buttons/NoFillBtn';
import ArrowUpDown from '../../assets/svg/arrowUpDown';
import CardHideContent from './CardHideContent/CardHideContent';

const Wrapper = styled.div`
    margin: 10px 0;
`;

const CardContainer = styled.div`
    display:flex;
    overflow: hidden;
    border-radius: 12px;
    ${(props) => !props.isCollapsed ? 'border-bottom-right-radius:0' : ''};
    flex-direction: column;
    @media screen and ${MEDIA_QUERY.tablet} {
        flex-direction: row;
    }
`;

const BeforeColumn = styled.div`
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 18px;
    color: ${COLORS.white};
    font-family: ${FONTS.bold};
    flex-basis: 40px;
    background: ${(props) => props.isRed ? COLORS.red : COLORS.darkGray};
    @media screen and ${MEDIA_QUERY.tablet} {
        transform: scale(-1);
        writing-mode: vertical-lr;
    }
`;

const Content = styled.div`
    width:100%;
    display:flex;
    background: ${COLORS.lightGray};
    flex-direction:column;
`;

const Line = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media screen and ${MEDIA_QUERY.tablet} {
        flex-direction: row;
    }
`;

const ColumnLine = styled.div`
    flex: 1;
    padding: 16px 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
        &:nth-child(1){
            color:${COLORS.semiGray}
        }
        &:nth-child(2){
            font-family: ${FONTS.bold};
        }
        display:block;
    }
`;

const Button = styled.div`
    width:200px;
`;

const DetailsText = styled.span`
    display:block;
    font-size: 16px;
    color:${COLORS.semiGray}
    font-family: ${FONTS.medium};
`;

const HiddenButton = styled.a`
    border:none;
    font-size: 16px;
    background: none;
    color:${COLORS.semiGray}
    font-family: ${FONTS.medium};
`

export default function OrdersOffersCard(props) {
    const { item, isOrder, type } = props;
    const [isCollapsed, setIsCollapsed] = useState(true);
    
    return (
        <Wrapper>
            <CardContainer isCollapsed={isCollapsed}>
                <BeforeColumn isRed={item.isBuy}>{item.title}</BeforeColumn>
                <Content>
                    <Line>
                        {isOrder && <ColumnLine><span>Статус</span><span>{item.status}</span></ColumnLine>}
                        <ColumnLine><span>Мероприятие</span><span>{item.event}</span></ColumnLine>
                        <ColumnLine><span>Город</span><span>{item.town}</span></ColumnLine>
                        <ColumnLine><span>Место</span><span>{item.place}</span></ColumnLine>
                        <ColumnLine><span>Дата</span><span>{item.date}</span></ColumnLine>
                    </Line>
                    <Line>
                        {isOrder
                            ? <ColumnLine>
                                {item.isBuy
                                    ? <Button><NoFillBtn color={COLORS.red} textValue="Оплатить" callback={() => { console.log('Оплатить'); }} /></Button>
                                    : <DetailsText>КУПЛЕН БУМАЖНЫЙ БИЛЕТ</DetailsText>}
                            </ColumnLine>
                            : <ColumnLine>
                                <HiddenButton onClick={() => {setIsCollapsed(!isCollapsed);}}>
                                    {isCollapsed
                                        ? 'СКРЫТЬ ДЕТАЛИ'
                                        : 'ПОКАЗАТЬ ДЕТАЛИ'}
                                        &nbsp;
                                    <ArrowUpDown isUp={!isCollapsed} />
                                </HiddenButton>
                            </ColumnLine>
                        }
                    </Line>
                </Content>
            </CardContainer>
            {!isOrder && <CardHideContent item={{title:'item'}} type={type} isCollapsed={isCollapsed}/>}
        </Wrapper>
    );
}
