import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS } from '../../config';

const Input = styled.input`
	box-sizing: border-box!important;
	position: relative;
	display: flex !important;
	padding: 14px 15px 16px !important;
	border-radius: 12px!important;
	border: 1.5px solid ${COLORS.black} !important;
	background-color: ${COLORS.white};
	margin: 0!important;
	box-shadow: none!important;
	height: auto !important;
	font-size: 18px !important;  
	line-height: 22px;
	letter-spacing: 0.02em;
	@media screen and ${MEDIA_QUERY.laptop} {
		max-width: 890px;
		margin: 0 auto!important;
		width: 100% !important;
	}
`;

//TODO refactor or remove component

export default function BaseInput(props) {
    const { placeholder, type, value, icon, id, onChange, } = props;

    return (
		<Input {...{ placeholder, type, value, icon, id, onChange }} />
    );
}
