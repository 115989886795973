import React from 'react';
import styled from "styled-components";
import { COLORS, MEDIA_QUERY } from "../../config";
import CardInfo from "../Cards/CardInfo/CardInfo";

const Container = styled.div`
  @media screen and ${MEDIA_QUERY.laptop} {
    display: flex;
    margin-top: 90px;
    column-gap: 34px;
  }
`;

const Additionally = () => {

    return (
        <Container>
            <CardInfo type={'Guarantee'} bgColor={`${COLORS.whiteBlue}`}  url={"/about/service"} />
            <CardInfo type={'noRisk'} bgColor={`${COLORS.whitePink}`} url={"/about/service"} />
        </Container>
    );
};

export default Additionally;