import React from 'react';
import { Container } from './styles';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import styled from 'styled-components';
import CollapsedLine from '../../../components/CollapsedLine/CollapsedLine';
import { QUESTIONS } from '../../../config';

const Content = styled.div``;
const CollapsedContainer = styled.div``;

export default function Help() {
    return (
        <Container>
            <TitleH2>Помощь</TitleH2>
            <Content>
                {QUESTIONS.map((item, index)=>(
                    <CollapsedLine key={index} title={item.title} text={item.text} />
                ))}
            </Content>
        </Container>
    );
}