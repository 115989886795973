import React from 'react';
import { Container, TextHigher, BoldText } from './styles';
import { TitleH2, Text } from '../../../modules/css/CommonStyles';

export default function Requisites() {
    return (
        <Container>
            <TitleH2>Реквизиты</TitleH2>
            <TextHigher>
                <BoldText>Наименование организации:</BoldText>    ООО «Eticket4» <br />
                <BoldText>Юридический адрес:</BoldText>    Пресненская набережная, 6с2, 1620, Москва, 123317 <br />
                <BoldText>Фактический адрес:</BoldText>    Пресненская набережная, 6с2, 1620, Москва, 123317 <br />
                <BoldText>Телефон:</BoldText>    +7 (499) 110-70-71 <br />
                <BoldText>Email:</BoldText>    support@eticket4.ru <br />
                <BoldText>Часы работы:</BoldText>    (10:00-19:00, будни) <br />
                <BoldText>ОГРН:</BoldText>    1137746647114 <br />
                <BoldText>ИНН:</BoldText> 7710944197 <br />
                <BoldText>КПП:</BoldText>    771001001 <br />
                <BoldText>Ф.И.О. Генерального директора:</BoldText>    Кручинин Даниил Александрович <br />
            </TextHigher>
        </Container>
    );
}
