import React from "react";
import { COLORS } from "../../config";

const CheckBox = (props) => {
    const isRound = props.isRound ? true : false;

    return (
            isRound
                ?<>
                    {props.isChecked
                        ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="12" fill={`${COLORS.red}`}/>
                            <path d="M6.71875 11.5206L11.0387 15.8406L17.2787 8.64062" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                        :<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" fill="white" stroke="#151820" strokeWidth="1.5"/>
                        </svg>
                    }
                </>
                :<>
                    {props.isChecked
                        ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="7" fill={`${COLORS.red}`}/>
                            <path d="M8 14.8125L12.9 21L22 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        :<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="6.25" fill="white"/>
                            <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="6.25" stroke="#151820" stroke-width="1.5"/>
                        </svg>                        
                    }
                </>
    );
}

export default CheckBox;