import React, { useState } from 'react';
import styled from 'styled-components';
import CollapsedLine from '../../components/CollapsedLine/CollapsedLine';
import SearchBlock from '../../components/SearchBlock/SearchBlock';
import EventBoard from '../../components/EventBoard/EventBoard';
import { TitleH3 } from '../../modules/css/CommonStyles';
import { QUESTIONS } from '../../config';

const Wrapper = styled.div`
    width: 100%;
	padding: 20px;
`;

const Container = styled.section`
	max-width: 1560px;
	margin: 50px auto;
`;

export default function SellTickets() {
	const [searchInput, setSearchInput] = useState("");

	return (
		<Wrapper>
			{/* TODO creat logic search, getEvent */}
			<SearchBlock
				options
				setInput={setSearchInput}
				title={'Продажа билетов'}
				placeholder={'Поиск мероприятия для продажи билета'}
				subTitle={`Введите название мероприятия, на которое вы хотите продать билеты`}
			/>
			<Container>
				<EventBoard title={'Мероприятия'} {...{searchInput}} isPathName />
				<TitleH3 textAlign={'center'}>Популярные вопросы</TitleH3>
				{QUESTIONS.slice(0, 5).map((item, index)=>(
					<CollapsedLine key={index} title={item.title} text={item.text} marginAuto />
				))}
			</Container>
		</Wrapper>
	);
}
