import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { COLORS, MEDIA_QUERY } from '../../config';
import styled from "styled-components";

const Container = styled.ul`
    background-color:${COLORS.lightGray};
    border-radius:20px;
    padding: 24px 32px !important;
    width:100%;
    font-family: 'Gilroy-medium';
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    @media screen and ${MEDIA_QUERY.tablet} {
		max-width:260px;
	}
`;

const Item = styled.li`
    cursor: pointer;
    padding:20px;
    a {
        color:${props => props.isActive? COLORS.red : COLORS.black};
    }
`;

const PageMenu = ({arrayMenu}) => {
    const location = useLocation();

    return (
        <Container>
            {arrayMenu.map((item, idx) => (
                <Item key={idx} isActive={location.pathname.includes(item.url)}>
                    <Link to={item.url}>{item.title}</Link>
                </Item>
            ))}
        </Container>
    );
};

export default PageMenu;