import React, { useState } from 'react';
import styled from 'styled-components';
import Additionally from '../../components/Additionally/Additionally';
import EventBoard from '../../components/EventBoard/EventBoard';
import { MEDIA_QUERY, COLORS, CATEGORIES } from '../../config';

const MainPanel = styled.div`
	max-width: 1560px;
	margin: 50px auto;
	padding: 20px;
`;

export default function Favourites() {

	return (
		<MainPanel>
            <EventBoard title={'Избранное'} typeBoard={'favourites'} />
            <Additionally />
		</MainPanel>
	);
}
