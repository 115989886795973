import { createEffect, createEvent, createStore, sample } from 'effector';
import AuthService from '../../API/authApi';
import { modalClose, modalOpen } from './modal';
import Utils from '../../services/utils/Utils';

export const setAuthData = createEvent();
export const sendSmsToUser = createEvent();
export const confirmSmsUser = createEvent();
export const resetAuthData = createEvent();

const defaultState = {phone:'', email:'', password:''};

export const $isLoadingAuth = createStore(false);
export const $authData = createStore({phone:'', email:'', password:''})
    .on(setAuthData, (state, payload)=> ({...state, ...payload}))
    .on(modalClose, ()=> (defaultState))
    .on(resetAuthData, ()=> (defaultState));

export const signInOrUpFx = createEffect(async (userPhone) => {
    const response = await AuthService.signInOrUp(userPhone);
    if(response.status == 200 && response.data.codeSend){
        setAuthData({phone:userPhone});
        modalOpen({modalName:'AuthModal', modalType:'confirmSmsView'});
    } else {
        //error handler?
    }
    return response
})

sample({
    clock: sendSmsToUser,
    target: signInOrUpFx
})
sample({
    clock: sendSmsToUser,
    fn: ()=> (true),
    target: $isLoadingAuth
})

sample({
    clock: signInOrUpFx.finally,
    fn: ()=> (false),
    target: $isLoadingAuth
})

export const confirmSmsFx = createEffect(async (smsCode) => {
    if(smsCode){
        const phone = $authData.getState().phone;
        const response = await AuthService.confirmSms(phone, smsCode);
        if(response.status == 200 && response.data){
            localStorage.setItem('user', JSON.stringify(response?.data));
            document.location.reload();
        }
    }
})

sample({
    clock: confirmSmsUser,
    target: confirmSmsFx
})
sample({
    clock: confirmSmsUser,
    fn: ()=> (true),
    target: $isLoadingAuth
})

sample({
    clock: confirmSmsFx.finally,
    fn: ()=> (false),
    target: $isLoadingAuth
})
