import React, { useState, useEffect } from 'react';
import PageMenu from '../../components/PageMenu/PageMenu';
import { Outlet, useNavigate } from "react-router-dom";
import { MEDIA_QUERY } from '../../config';
import styled from 'styled-components';


const Container = styled.div`
    display:flex;
    width: 100%;
    padding:20px;
    align-items:flex-start;
    justify-content: center;
    flex-direction: column;
    @media screen and ${MEDIA_QUERY.tablet} {
		flex-direction: row;
	}
`;

const Content = styled.div`
    width: 100%;

    
    @media screen and ${MEDIA_QUERY.tablet} {
		width: 70%;
        padding-left: 40px;
        
	}
`;

const menu = [
    { url: "personal", title:"Личные данные" },
    { url: "payment-betails", title:"Платежные данные" },
    { url: "orders", title:"Мои заказы" },
    { url: "offers", title:"Мои предложения" },
    { url: "payments", title:"Выплаты" }
];

export default function ProfileUser() {
    const [currentType, setCurrentType] = useState('personal');
    const userData = Boolean(localStorage.getItem('user'));
    const navigate = useNavigate();

    useEffect(() => {
        !userData && navigate("/");
        //TODO add check token, and loader wait check token
    }, [userData])

    return (
        <Container>
            <PageMenu arrayMenu={menu} activeType={currentType} setActiveType={setCurrentType} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
}
