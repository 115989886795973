import React from 'react';
import styled from 'styled-components';

const Btn = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  border-radius: 32px;
  min-width: 220px;
  padding: 10px;
  color: #19214E;
  border: 2px solid #19214E;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;

`;

export default function OpacityBtn(props) {
  return (
    <Btn href={props.href}>
      Как продать?
    </Btn>
  );
}
