import React from 'react';
import { Container } from './styles';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import FormPersonalData from '../../../components/Forms/FormPersonalData';

export default function PersonalData() {

    return (
        <Container>
            <TitleH2>Личные данные</TitleH2>
            <FormPersonalData />
        </Container>
    );
}