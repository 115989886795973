import React from "react";

const ArrowRight = (props) => {
  return (
    <div>
      <svg
          className={`${props.className ? props.className : ''}`}
          viewBox="0 0 27 46"
          style={{
            enableBackground: "new 0 0 27 46",
          }}
          xmlSpace="preserve"
        >
          <path
            d="m25.8 20.2-19-19C5.2-.4 2.7-.4 1.1 1.2c-1.6 1.6-1.6 4.1 0 5.7L17.4 23 1.2 39.2c-1.6 1.6-1.6 4.1 0 5.7 1.6 1.6 4.1 1.6 5.7 0l19-19c1.5-1.6 1.5-4.2-.1-5.7z"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#000",
            }}
          />
      </svg>
    </div>
  );
}

export default ArrowRight;