import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '../../../config';
import ApprovalHand from '../../../assets/svg/approvalHand';
import CardInCircle from '../../../assets/svg/cardInCircle';
import MailInCircle from '../../../assets/svg/mailInCircle';
import { TitleH2 } from '../../../modules/css/CommonStyles';
import OpacityBtn from '../../Buttons/OpacityBtn';

const Container = styled.div`
    width:100%;
    padding: 20px;
    margin-top:24px;
    border-radius:20px;
    aspect-ratio: 2 / 1;
    background-color:${(prop)=>(prop.bgColor ? `${prop.bgColor}` :`${COLORS.lightGray}`)};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size:18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    font-family: ${FONTS.regular};
    & h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        width: 100%;
        color: ${COLORS.black};
        margin-bottom: 10px;
        text-align: center;
    }
`;

const cards = {
    'ApprovalHand':{icon: <ApprovalHand />, text:"Eticket4 не взимает комиссию\n за продажу билетов"},
    'CardInCircle':{icon: <CardInCircle />, text:'Укажите платежные данные в Личном кабинете,\n чтобы получить деньги за продажу билета'},
    'MailInCircle':{icon: <MailInCircle />, text:'Мы сообщим Вам, когда мероприятие\n будет добавлено в базу Eticket4'},
    'Guarantee':{title: 'Гарантируем безопасность', text:'На Eticket4 продаются только оригинальные билеты на мероприятия. Все платежи, производимые на сайте, надежно защищены.'},
    'noRisk':{title: 'Продавайте билеты без риска', text:'Если у вас поменялись планы на вечер и не получается посетить мероприятие, мы поможем вам продать билеты, чтобы они не пропали зря!'}
}

export default function CardInfo(props) {
    const { bgColor, type, url } = props;
    const data = cards[ type ? type : 'ApprovalHand'];
    

    return (
            <Container bgColor={bgColor}>
                    {data.icon? data.icon : <TitleH2>{data.title}</TitleH2>}
                    <p>{data.text}</p>
                    {/* TODO REFACTOR BUTTONs */}
                    {url && <OpacityBtn href={url}>
                        Как продать?
                    </OpacityBtn>}
            </Container> 
    );
}
