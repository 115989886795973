import styled from 'styled-components';
import { FONTS } from '../../config'; 

export const TitleH1 = styled.h1`
    font-family: ${FONTS.medium}, sans-serif;
    font-weight: 700;
    font-size: 46px;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:54px;')}
`;

export const TitleH2 = styled.h2`
    font-family: ${FONTS.medium}, sans-serif;
    font-weight: 700;
    font-size:32px;
    margin: 0 !important;
    padding: 2.3733333333rem 0 1.424rem 0 !important;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:38px;')}
`;

export const TitleH3 = styled.h3`
    font-family: ${FONTS.medium}, sans-serif;
    font-weight: 700;
    font-size:24px;
    margin: 0 !important;
    padding: 1.9466666667rem 0 1.168rem 0 !important;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:29px;')}
`;

export const TitleH4 = styled.h4`
    font-family: ${FONTS.medium}, sans-serif;
    font-weight: 600;
    font-size:20px;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:24px;')}
`;

export const SubText1 = styled.span`
    font-family: ${FONTS.medium};
    font-weight: 500;
    font-size: 24px;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:30px;')}
`;
export const SubText2 = styled.span`
    font-family: ${FONTS.medium};
    font-weight: 500;
    font-size: 16px;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
    ${(prop)=>(prop.lineHeight? `line-height:${prop.lineHeight};` : 'line-height:22px;')}
`;

export const Text = styled.p`
    font-family: ${FONTS.medium}, sans-serif;
    font-weight: 500;
    line-height: 26px;
    font-size:18px;
    ${(prop)=>(prop.textAlign? `text-align:${prop.textAlign};` : '')}
`;

export const TextBold = styled.span`
    font-family: ${FONTS.bold}, sans-serif;
    font-weight: 700;
    ${(prop)=>(prop.color? `color:${prop.color};` : '')}
`;

// // // OLD
// const Title = styled.h2`
// 	margin-bottom: 16px;
// 	margin-top: 0;
// 	font-size: 22px;
// 	line-height: 24px;
// 	color: ${COLORS.black};
// 	text-align: center;
// 	font-weight: bold;

// 	@media screen and ${MEDIA_QUERY.laptop} {
// 		font-size: 32px;
// 		line-height: 38px;
// 		text-align: left;
// 	}
// `;