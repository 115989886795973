import React from 'react';
import styled from 'styled-components';
import ModalTemplateHeader from '../ModalTemplateHeader';
import FillBtn from '../../../components/Buttons/FillBtn';
import BaseInput from '../../../components/Inputs/BaseInput';
import AuthService from '../../../API/authApi';

const ViewText = styled.p`
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
`;

export default function AccessRecoveryView() {

	const sendLink = () => {
		const login = document.getElementById('emailInAuthModalConfirmLinkId').value;
		AuthService.sendConfirmLink(login);
	};

	return (
		<>
			{/* <ModalTemplateHeader title="Восстановить доступ" /> */}
			<ModalTemplateHeader title="Восстановить доступ" />
			<ViewText>
				Введите email, который вы использовали для входа на Eticket4. Мы отправим на него новый пароль
			</ViewText>
			<BaseInput placeholder="Email" icon="none" id="emailInAuthModalConfirmLinkId" />
			<FillBtn isActive title="ОТПРАВИТЬ ПАРОЛЬ" callback={sendLink} />
		</>
	);
}
