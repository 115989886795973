import React, { useState } from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS, FONTS } from '../../config';
import ArrowUpDown from '../../assets/svg/arrowUpDown';

const Container = styled.div`
    ${props => props.marginAuto
        ? ` margin: 5px auto;
            max-width: 1000px;` 
        : `margin-top:10px;`}
    border-radius: 12px;
    border: 1px solid ${COLORS.black};
`;

const Header = styled.a`
    margin:0;
    padding: 22px;
    font-family: ${FONTS.bold};
    color: ${COLORS.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Body = styled.p`
    margin:0;
    padding: 0 0 22px 22px;
    ${props => props.isCollapsed ? `display:none;` : ``}
`;

export default function CollapsedLine(props) {
    const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<Container marginAuto={props.marginAuto}>
                <Header onClick={()=>{setIsCollapsed(!isCollapsed)}}>{props.title} <ArrowUpDown isUp={!isCollapsed} /></Header>
                <Body isCollapsed={isCollapsed}>
                    {props.text}
                </Body>
		</Container>
	);
}
