import React from 'react';
import { $modalState } from "../../../modules/effector/modal";
import AccessRecoveryView from './AccessRecoveryView';
import SignInByLoginView from './SignInByLoginView';
import SignInByPhoneView from './SignInByPhoneView';
import CodeConfirmView from './CodeConfirmView';
import { useStore } from "effector-react";

export default function AuthModal() {
    const modalState = useStore($modalState);

	return (
		<>
            {(() => {
                switch (modalState.modalType) {
                    case 'sendLinkView':
                        return <AccessRecoveryView />;
                    case 'confirmSmsView':
                        return <CodeConfirmView />;
                    case 'loginView':
                        return <SignInByLoginView />;
                    case 'phoneView':
                        return <SignInByPhoneView />;
                }
            })()}
        </>
	);
}
