import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { $currentEvent } from '../../modules/effector/currentEvent';
import ArenaContentList from './ArenaContentList/ArenaContentList';
import { COLORS, FONTS, BREAK_POINTS } from '../../config';
import ArrowRight from '../../assets/svg/arrowRight';
import ArenaScheme from './ArenaScheme/ArenaScheme';
import Utils from '../../services/utils/Utils';

const Container = styled.section`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 1520px;
  width: 100%;
  margin: 70px auto;
  overflow: hidden;
  height: 600px;
  background: #F5F5F5;
  padding: 10px;
  border-radius: 20px;
  @media screen and (max-width: ${BREAK_POINTS.tablet}) {
		height: 100vh;
    margin: 0;
	}
`;

const ContainerArena = styled.div`
width:100%;
display: ${(props)=> {
  if(props.isMobile) {
    return props.isOpen ? 'block' : 'none'
  } else {
    return 'block'
  }
} };
  .zoomContent, .zoomWrapper{
    width:100%;
  }
`;


const ContainerContent = styled.div`
  display: ${(props)=> {
    if(props.isMobile) {
      return props.isOpen ? 'block' : 'none'
    } else {
      return 'block'
    }
  } };

  position:absolute;
  height: 100%;
  width:100%;
  max-width: 350px;
  right:0;
  top:0;

  
  font-family: ${FONTS.medium};

  @media screen and (max-width: ${BREAK_POINTS.tablet}) {
		max-width: 100%;
	}
`;

const MobileHeader = styled.div`
  display: ${(props)=> props.isMobile ? 'block' : 'none' };
  padding: 10px;
  
  .title{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    .info{
      display:flex;
      padding-left:5px;
      flex-direction: column;
    }
    .goBack{
      height: 44px;
      width: 40px;
      padding:10px;
      border: 1px solid;
      .arrow{
        height:20px;
        width:17px;
        transform: rotate(180deg);
      }
    }
  }

  .views{
    display:flex;
    padding-top: 15px;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    text-transform: uppercase;
    font-family: ${FONTS.medium};
    .vBtn{
      display:flex;
      .btnView{
        padding:10px;
        color:${COLORS.red};
        background-color: ${COLORS.white};
        border: 2px solid ${COLORS.red};
  
        &.choose{
          background-color: ${COLORS.red};
          color:${COLORS.white};
        }
  
        &.btnScheme{
          border-radius: 5px 0 0 5px;
        }
        &.btnList{
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
`;

export default function Arena() {
  const [isScheme, setIsScheme] = useState(true);
  const { eventDetail } = useStore($currentEvent);
  const isMobile = Utils.isMobile();
  const navigate = useNavigate();

  const onOpenList = ()=>{
    setIsScheme(false);
  };
  
  return (
    <>
      <MobileHeader isMobile={isMobile}>
        <div className="title">
          <div className="goBack" onClick={()=>{navigate(-1)}}> <ArrowRight className='arrow' /></div>
          <div className="info">
            <div>{eventDetail.name}</div>
            <div>{Utils.dateToString(eventDetail.start, true)} {eventDetail.city}</div>
          </div>
        </div>
        <div className="views">
          <div className="vTitle">
            <span>Вид:</span>
          </div>
          <div className="vBtn">
            <div className={`btnView btnScheme ${isScheme? 'choose' : ''}`} onClick={()=>{setIsScheme(true)}}>Схема</div>
            <div className={`btnView btnList ${isScheme? '' : 'choose'}`} onClick={()=>{setIsScheme(false)}}>Список</div>
          </div>
        </div>
      </MobileHeader>
      <Container>
        <ContainerArena isOpen={isScheme} isMobile={isMobile}>
          <ArenaScheme onOpenList={onOpenList} />
        </ContainerArena>
        <ContainerContent isOpen={!isScheme}isMobile={isMobile}>
          <ArenaContentList />
        </ContainerContent>
      </Container>
    </>
  );
}
