import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ChipList from '../Chip/ChipList';
import { CATEGORIES } from '../../config';
import { TitleH2 } from '../../modules/css/CommonStyles';
import { MEDIA_QUERY, COLORS, FONTS } from '../../config';
import FillBtn from '../../components/Buttons/FillBtn';
import HooksApi from '../../API/hooksApi';
import Utils from '../../services/utils/Utils';
import parse from "html-react-parser";

const Wrapper = styled.section`
	${props => `background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${props.imgLink});`}
	background-position: center;
	background-size: cover;
	position: relative;
	width: 100%;
	min-height: 470px;
	padding: 62px 36px;
	color: ${COLORS.white};
	border-radius:20px;
	p{
		font-family: ${FONTS.regular};
		font-size: 16px;
	}
	.buttonBuy{
		width:280px;
	}
`;

export default function CurrentEvent(props) {
	const [srcImg, setSrcImg] = useState('');
	const { name, years_txt:yearsTxt, categoryNameRu, arenaName, city, start, description, id,  } = props.event.eventDetail;
	const navigate = useNavigate();
	const { dateToString } = Utils;

	useEffect(() => {
		HooksApi.getImageForEvent(id)
		.then((data) => setSrcImg(data));
	}, [id]);

	const navigateArenaHandler = (evt) => {
		evt.preventDefault();
		if (id) navigate(`/selltickets/event/${id}`);
	};

	if(srcImg) {
		return (
			<>
				<Wrapper imgLink={srcImg}>
					<TitleH2>{name} {yearsTxt}</TitleH2>
					<p>{parse(description)}</p>
					<FillBtn className="buttonBuy" callback={(evt) => navigateArenaHandler(evt)} title="КУПИТЬ БИЛЕТЫ" isActive />
				</Wrapper>
				<TitleH2>{dateToString(start, true)}</TitleH2>
			</>
		);
	} else {
		return (<></>)
	}
}
