import React, { useState } from 'react';
import { Container, ContainerCollapse } from './styles';
import { TitleH2, Text } from '../../../modules/css/CommonStyles';
import FillBtn from '../../../components/Buttons/FillBtn';

export default function LicensingAgreement() {
    const [isReadAll, setIsReadAll] = useState(false);
    return (
        <Container>
            <TitleH2>Лицензионный договор</TitleH2>
            <Text>
                1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                <br />1.1. Билет – документ, удостоверяющий право его обладателя на посещение Мероприятия. Билет выполняется в соответствии с формой, установленной для конкретного вида услуг, бланка строгой отчетности и может быть выполнен (i) на термобланке, (ii) на бланке, сформированном посредством автоматизированной системы (Системы) - «Электронный билет».
                <br />1.2. Договор - настоящий Договор, заключаемый на условиях публичной оферты ООО «Етикет» на использование Площадки, опубликованный по адресу в сети Интернет https://eticket4.ru/info/license
                <br />1.3. Лицензиар – Общество с ограниченной ответственностью «Етикет» (121205, город Москва, территория Сколково инновационного центра, Большой бульвар, дом 42, строение 1, этаж 1, помещение 332 РМ35; ОГРН 1167746298290; ИНН 7704350420).
                <br />1.4. Лицензиат - лицо, осуществляющее регистрацию на Площадке с целью отчуждения принадлежащих Лицензиату прав на посещение Мероприятия (как за плату, так и безвозмездно).
                <br />1.5. Лицензия – право возмездного функционального использования Площадки на условиях простой (неисключительной) лицензии в пределах функциональных возможностей Площадки.
                <br />1.6. Мероприятие – культурно-зрелищное мероприятие, в том числе театральный спектакль, цирковое представление, концерт в зале, клубе или на открытой площадке, выставка, кинопоказ, фестиваль, шоу, показ мод, спортивное соревнование, экскурсия, а также любое иное событие, посещение которого возможно только по предъявлению Билета.
                <br />1.7. Площадка – функционал интернет-сайта https://eticket4.ru, принадлежащего Лицензиару, позволяющий осуществлять поиск, подбор, бронирование, приобретение и оплату билетов на Мероприятия, доступные на Площадке.
                <br />1.8. Приобретатель – лицо, приобретающее Билет посредством Площадки.
                <br />1.9. Стороны – Лицензиар и Лицензиат, как они определены выше.

                <br /><br />2. ОБЩИЕ ПОЛОЖЕНИЯ
                <br />2.1. Настоящий Договор регламентирует условия и порядок взаимоотношения Сторон. Положения настоящего Договора считаются принятыми Лицензиатом с момента регистрации на Площадке.
                <br />2.2. Использование Площадки не допускается в случае, если Лицензиат не в полном объеме принимает условия настоящего Договора. Отказ от исполнения Договора на основании несогласия Лицензиата с условиями настоящего Договора не допускается.
                <br />2.3. Условия настоящего Договора в полной мере действительны и распространяются на Лицензиата, вплоть до момента исполнения Лицензиатом и Лицензиаром всех своих взаимных обязательств.

                <br /><br />3. ПРЕДМЕТ ДОГОВОРА
                <br />3.1. Лицензиар предоставляет зарегистрированному и авторизованному Лицензиату Лицензию на использование функциональных возможностей Площадки, как она определена пунктом 1.7. настоящего Договора.
                <br />3.2. Лицензиат обязуется выплатить Лицензиару вознаграждение, в порядке и на условиях, определенных настоящим Договором.
                <br />3.3. Использование Площадки иными способами, кроме отчуждения Билетов на Мероприятия запрещается.
                <br />3.4. Лицензиар не несет ответственности за Лицензиата, а равно за организацию, сам факт проведения и содержание Мероприятий.

                { isReadAll && <ContainerCollapse>
                <br /><br />4. ПОРЯДОК И УСЛОВИЯ АКЦЕПТА
                <br />4.1. Лицензиат подтверждает и соглашается, что до совершения им действий по акцепту, установленных настоящим Договором, он ознакомился с условиями настоящего Договора и иными обязательными правилами, установленными Лицензиаром и Площадкой. Лицензиат подтверждает и соглашается с тем, что условия настоящего Договора и иных обязательных правил ему полностью понятны.
                <br />4.2. Лицензиат подтверждает и соглашается с тем, что все действия (бездействие) его представителя (представителей) совершаются с его ведома, согласия и в его интересах.
                <br />4.3. Акцепт настоящего Договора совершается Лицензиатом путем последовательного совершения следующих действий:
                <br />4.3.1. Регистрация на Площадке;
                <br />4.3.2. Передача Лицензиару персональных данных Лицензиата, как они определены в разделе 10 настоящего Договора.
                <br />4.4. Совершение действий, указанных в пунктах 4.3.1 и 4.3.2 настоящего Договора, признается полным и безоговорочным принятием Лицензиатом всех условий настоящего Договора без каких-либо изъятий и (или) ограничений (акцептом) и равносильно заключению договора в простой письменной форме (пункт 3 статьи 434 ГК РФ).

                <br /><br />5. ПРАВА И ОБЯЗАННОСТИ СТОРОН
                <br />5.1. Лицензиат вправе:
                <br />5.1.1. Самостоятельно определять стоимость Билета, подлежащего отчуждению посредством Площадки.
                <br />5.1.2. Изменять стоимость Билета до момента выдачи согласия Приобретателя на приобретение Билета, выраженного посредством функционала Площадки.
                <br />5.1.3. Отменять предложение Билета в любой момент, до момента отчуждения Билета.
                <br />5.2. Лицензиат обязан:
                <br />5.2.1. Строго следовать условиям настоящего Договора;
                <br />5.2.2. Использовать функционал Площадки только по прямому назначению, а именно: отчуждение Билетов. Запрещается копирование, декомпиляция, модификация и иные действия с программным кодом Площадки;
                <br />5.2.3. Выплачивать Лицензиату вознаграждение в соответствии с условиями настоящего Договора.
                <br />5.2.4. Возвратить стоимость Билета Лицензиару в размере суммы денежных средств, переданных Лицензиаром Лицензиату в порядке, предусмотренном пунктом 6.2. настоящего Договора, в тех случаях, в случаях, когда в силу закона Лицензиар вправе требовать возврата стоимости Билета.
                <br />5.3. Лицензиар вправе:
                <br />5.3.1. При отсутствии у Лицензиата полного акцепта настоящего Договора отказать ему в использовании Площадки;
                <br />5.3.2. Проводить, как планово, так и внепланово, модификацию, замену, переустановку программного обеспечения Площадки, приостанавливать работу такого программного обеспечения при обнаружении неисправностей, ошибок и (или) сбоев, а также в целях проведения профилактических работ и предотвращения случаев несанкционированного доступа к Площадке;
                <br />5.3.3. В одностороннем порядке изменять условия оплаты и размер Лицензионного вознаграждения, при этом Лицензиат обязуется своевременно и самостоятельно отслеживать указанные изменения путем обращения к тексту настоящего Договора, расположенного по адресу в сети Интернет: https://eticket4.ru/info/license
                <br />5.4. Лицензиар обязан:
                <br />5.4.1. Надлежащим образом исполнять обязательства, предусмотренные настоящим Договором.
                <br />5.4.2. Организовать передачу Билета от Лицензиата к Приобретателю.
                <br />5.4.3. После передачи Билета Лицензиатом направить Лицензиату на его электронный адрес подтверждение факта передачи Билета в соответствии с пунктом 6.3. настоящего Договора.
                <br />5.4.4. Произвести оплату Билета в соответствии с условиями настоящего Договора.

                <br /><br />6. ПОРЯДОК ОТЧЕТНОСТИ И СОВЕРШЕНИЯ ПЛАТЕЖЕЙ
                <br />6.1. Лицензионное вознаграждение за пользование Лицензией составляет 40% от стоимости Билета, и удерживается Лицензиаром из денежных средств, перечисленных Приобретателем в счет оплаты Билета.
                <br />6.2. После оплаты Билета Приобретателем, денежные средства, перечисленные Приобретателем Лицензиару, Лицензиар перечисляет Лицензиату и удерживает сумму Лицензионного вознаграждения в размере, установленном пунктом 6.1. настоящего Договора.
                <br />6.3. В течение 5 (пяти) часов с момента передачи Лицензиатом Билета лицу, уполномоченному Лицензиаром на доставку Билета Приобретателю, Лицензиар направляет Лицензиату подтверждение передачи Билета. Подтверждение выполняется по форме, определяемой Лицензиаром, и подлежит отправке на адрес электронной почты Лицензиата, указанный при регистрации на Площадке и считается полученным Лицензиатом с момента такой отправки.

                <br /><br />7. ОТВЕТСТВЕННОСТЬ СТОРОН
                <br />7.1. В случае неисполнения или ненадлежащего исполнения своих обязательств по настоящему Договору, Стороны несут ответственность в соответствии с законодательством Российской Федерации и условиями настоящего Договора.
                <br />7.2. Лицензиат принимает на себя все возможные риски, связанные с его действиями по возможному допущению ошибок и неточностей в предоставленных им данных, необходимых для передачи Билета, а также в платежных реквизитах, необходимых для перечисления ему Лицензиаром стоимости реализованного Билета. Лицензиар не несет ответственности в случае, если банком-отправителем или банком-получателем будет отказано в перечислении денежных средств, вследствие ошибочного указания Лицензиатом своих платежных реквизитов.
                <br />7.3. Лицензиар не несет ответственности за любые убытки и моральный вред, понесенные Лицензиатом в результате ошибочного понимания или непонимания им информации о порядке оформления/оплаты Билета, а также получения и использования сопутствующих услуг, предоставляемых Площадкой.
                <br />7.4. Лицензиат подтверждает и соглашается с тем, что ни при каких обстоятельствах Лицензиар, его работники или любые другие лица, в том числе действующие от имени Лицензиара, не несут ответственности за любые прямые или косвенные убытки, возникшие в результате продажи Билета, а также в результате несанкционированного доступа к персональным данным Лицензиата.
                <br />7.5. Если Договор не был исполнен по причинам, зависящим от Лицензиата, Лицензиар сохраняет право на возмещение понесенных расходов.
                <br />7.6. Стороны освобождаются от ответственности за неисполнение и (или) ненадлежащее исполнение своих обязательств по настоящему Договору в случае наступления обстоятельств непреодолимой силы, т.е. обстоятельств, носящих объективных характер, находящихся вне воли Сторон и возникших после заключения настоящего Договора. К обстоятельствам непреодолимой силы относятся, в частности забастовки, наводнения, землетрясения, ураганы, иные стихийные бедствия и военные действия (локального и международного масштаба), заболевания и (или) недомогания артистов, участвующих в Мероприятии, а равно техногенные и антропогенные катастрофы, а равно акты органов государственной власти и местного самоуправления, а равно действия (бездействие) контрагентов Площадки, затрудняющие без несоразмерных потерь надлежащее исполнение договоров, заключенных на условиях настоящего Договора.
                <br />7.7. Лицензиар не несет ответственности за работоспособность и (или) защищенность информационных каналов связи, используемых Лицензиатом, в том числе используемых Лицензиатом для обращения к интернет-сайту https://eticket4.ru

                <br /><br />8. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
                <br />8.1. Все споры или разногласия, возникающие в связи с исполнением и (или) неисполнением, и (или) ненадлежащим исполнением положений настоящего Договора разрешаются с обязательным соблюдением претензионного порядка. Сторона, полагающая свои права нарушенными, направляет другой стороне в простой письменной форме претензию с приложением надлежащим образом заверенных документов, обосновывающих изложенные требования, в течение 5 (пяти) дней с момента возникновения спора. Срок рассмотрения претензии составляет 30 (тридцать) рабочих дней с момента ее получения.
                <br />8.2. В случае нарушения срока направления претензии, указанного в пункте 8.1 настоящего Договора, такая претензия не подлежит рассмотрению.
                <br />8.3. В случае невозможности разрешения возникшего спора в порядке, установленном пунктом 8.1 настоящего Договора, такой спор подлежит рассмотрению судом по месту нахождения Лицензиара.

                <br /><br />9. ВСТУПЛЕНИЕ В СИЛУ, ИЗМЕНЕНИЕ ПОЛОЖЕНИЙ ДОГОВОРА
                <br />9.1. Настоящий Договор вступает в силу с момента акцепта оферты Лицензиаром, в соответствии с положениями пункта 4.3. настоящего Договора и действует до момента исполнения Лицензиаром и Лицензиатом всех своих взаимных обязательств. Положения настоящего пункта распространяются также и на изменения и (или) дополнения к Договору.
                <br />9.2. Все изменения и (или) дополнения, вносимые в настоящий Договор, подлежат размещению на Площадке и вступают в силу с момента размещения таких изменений и (или) дополнений на Площадке.
                <br />9.3. Лицензиар вправе в любое время вносить изменения в текст настоящего Договора, но в любом случае такие изменения публикуются и доводятся до всеобщего сведения посредством опубликования по адресу в сети Интернет: https://eticket4.ru/info/license. Использование Лицензиатом Площадки после внесения изменений в текст настоящего Договора означает полное и безоговорочное принятие его условий, с учетом внесенных изменений.

                <br /><br />10. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ
                <br />10.1. Лицензиат дает согласие Лицензиару на обработку своих персональных данных в соответствии со ст.9 Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» (с учетом изменений и дополнений).
                <br />10.2. Оператором обработки персональных данных Лицензиата является Лицензиар.
                <br />10.3. Согласие дано Лицензиатом свободно, своей волей и в своем интересе. Согласие является конкретным, информированным и сознательным. Лицензиат не обязан предоставлять Лицензиару данные, касающиеся его расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, а также информацию, составляющую врачебную тайну.
                <br />10.4. Перечень персональных данных Лицензиата, на обработку которых дается согласие:
                <br />10.4.1. Фамилия, Имя, Отчество Лицензиата;
                <br />10.4.2. Данные документа, удостоверяющего личность Лицензиата;
                <br />10.4.3. Данные СНИЛС, ИНН;
                <br />10.4.4. Пол Лицензиата;
                <br />10.4.5. День, месяц и год рождения Лицензиата;
                <br />10.4.6. Место рождения Лицензиата;
                <br />10.4.7. Гражданство Лицензиата;
                <br />10.4.8. Адрес электронной почты Лицензиата;
                <br />10.4.9. Номер телефона Лицензиата;
                <br />10.4.10. Изображение Лицензиата;
                <br />10.4.11. Номер банковского счета (номера банковских счетов), номер банковской карты (номера банковских карт), иные реквизиты для безналичного перечисления денежных средств;
                <br />10.4.12. Любые иные данные, которые могут быть получены в ходе исполнения Договора, в т.ч. любые данные, касающиеся использования Площадки Лицензиатом, включая его поведение на Площадке, клики и проч., любые данные.
                <br />10.5. Перечень действий с персональными данными Лицензиата и способов их обработки: любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными Лицензиата, включая, но не ограничиваясь, следующее: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (в т.ч. передачу иным лицам), обезличивание, блокирование, удаление, уничтожение.
                <br />10.6. Цели обработки персональных данных Лицензиара включают следующее:

                <br />10.6.1. Исполнение Сторонами условий настоящего Договора;

                <br />10.7. Срок согласия: согласие действует с даты заключения настоящего Договора (с даты акцепта Лицензиатом условий настоящего Договора) и автоматически прекращается по истечении 10 (десяти) лет с даты последнего случая использования Лицензиатом Площадки.
                <br />10.8. Способ отзыва согласия: отзыв согласия осуществляется путем направления письменного документа, содержащего информацию об отзыве, по юридическому адресу Лицензиара.
                <br />10.9. Лицензиар обязуется обеспечить соответственно:
                <br />10.9.1. Конфиденциальность персональных данных Лицензиата;
                <br />10.9.2. Безопасность персональных данных Лицензиата при их обработке в соответствии c требованиями законодательства России;
                <br />10.9.3. Выполнение всех требований к защите обрабатываемых персональных данных Лицензиата, предусмотренных в статье 19 Федерального закона от 27 июля 2006 г.№ 152-ФЗ «О персональных данных» (с учетом изменений и дополнений) и принятыми в соответствии с ним подзаконными актами.
                </ContainerCollapse>}
            </Text>
            <FillBtn
                title={isReadAll ? "Свернуть" : "Читать полностью"}
                callback={() => { setIsReadAll(!isReadAll) }}
            />
        </Container>
    );
}